import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UsersGrid from "./UsersGrid";
import AddUser from "./Modal/AddUser";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Project } from "../../api";
import { useSnackbar } from "../../hooks/useSnackbar";
import UpgradeButton from "./UpgradeButton";

const ProjectOverview = () => {
  const { state } = useLocation();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [project, setProject] = useState(state.project);
  const [projectUsers, setProjectUsers] = useState(null);
  const [remainingQuota, setRemainingQuota] = useState(0);

  const addUserModalOpenHandler = (project) => {
    setIsAddUserModalOpen(true);
  };

  const addUserModalCloseHandler = () => {
    getUsersForProject();
    getRemainingUserQuota();
    setIsAddUserModalOpen(false);
  };

  const onGoBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    getRemainingUserQuota();
    getUsersForProject();
  }, []);

  const getUsersForProject = async () => {
    const payload = {
      projectId: project.projectId,
    };

    const usersResponse = await Project.getUsers(payload);
    if (!usersResponse.error) {
      setProjectUsers(usersResponse.data.userInfos);
    } else {
      showSnackbar("Getting Users for project failed", "error");
      console.error(usersResponse.error);
    }
  };

  const getRemainingUserQuota = async () => {
    const payload = {};

    const res = await Project.getRemainingUserQuota(payload);
    if (!res.error) {
      setRemainingQuota(res.data.remainingUserQuota);
    } else {
      console.error(res.error);
      //TODO : Need to find a better way to handle this error
    }
  };

  const onRemoveUserFromProject = async (userId) => {
    const payload = {
      user_id: userId,
      project_id: project.projectId,
    };

    const res = await Project.removeUser(payload);
    if (!res.error) {
      getUsersForProject();
      showSnackbar("User removed from project", "success");
    } else {
      showSnackbar("Error removing users from project", "error");
      console.error(res.error);
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ bgcolor: "white", height: "100vh" }}>
        <Box
          pt={2}
          pb={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box display="flex" alignItems="center">
            <IconButton onClick={onGoBackHandler}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h4" component="h4">
              {project?.projectName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <UpgradeButton />
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              disabled={remainingQuota === 0}
              onClick={addUserModalOpenHandler}
            >
              Add User
            </Button>
          </Box>
        </Box>
        <UsersGrid
          projectUsers={projectUsers}
          project={project}
          removeUser={onRemoveUserFromProject}
        />
      </Container>
      <AddUser
        open={isAddUserModalOpen}
        handleClose={addUserModalCloseHandler}
        project={project}
      />
    </>
  );
};

export default ProjectOverview;
