import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getVersionedUrl } from "../../common/Util";

const ProjectsGrid = ({
  projects,
  capacity,
  usedCapacities,
  setProjects,
  organizationDetails,
  updateProjectCapacities,
  isSaving,
}) => {
  const navigate = useNavigate();
  const [activeInput, setActiveInput] = useState(null);

  const onViewProjectHandler = (project) => {
    navigate(getVersionedUrl("/admin-settings/overview"), {
      state: { project: project },
    });
  };

  const handleInputChange = (e, id, fieldName) => {
    const newValue = e.target.value;
    setActiveInput({
      id,
      fieldName,
    });
    setProjects((prevData) =>
      prevData?.map((item) =>
        item.projectId === id
          ? {
              ...item,
              projectCapacityConfig: {
                ...item.projectCapacityConfig,
                [fieldName]: newValue === "" ? 0 : +newValue,
              },
            }
          : item
      )
    );
  };

  const total = useMemo(() => {
    return projects?.reduce((prevValue, currValue) => {
      return (
        prevValue + currValue.projectCapacityConfig.totalAllowedStorageCapacity
      );
    }, 0);
  }, [projects]);

  const getUsedCapacityForProject = (key) => {
    if (usedCapacities && usedCapacities.hasOwnProperty(key)) {
      return usedCapacities[key];
    }
    return "";
  };

  return (
    <section style={{ marginTop: "1rem" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Project Name</strong>
              </TableCell>
              <TableCell>
                <strong>Project ID</strong>
              </TableCell>
              <TableCell>
                <strong>Total Capacity</strong>
              </TableCell>
              <TableCell>
                <strong>Used Capacity</strong>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects?.map((data) => {
              return (
                <TableRow key={data.projectId}>
                  <TableCell component="th" scope="row">
                    {data.projectName}
                  </TableCell>
                  <TableCell>{data.projectId}</TableCell>
                  <TableCell>
                    <TextField
                      key={data.projectId}
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="number"
                      defaultValue={
                        data.projectCapacityConfig.totalAllowedStorageCapacity
                      }
                      error={
                        activeInput &&
                        activeInput.id === data.projectId &&
                        activeInput.fieldName ===
                          "totalAllowedStorageCapacity" &&
                        total > capacity
                      }
                      onInput={(e) =>
                        handleInputChange(
                          e,
                          data.projectId,
                          "totalAllowedStorageCapacity"
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Million EPM</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {getUsedCapacityForProject(data.projectId)}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button onClick={() => onViewProjectHandler(data)}>
                        View
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" py={1}>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          disableElevation
          disabled={total > capacity}
          onClick={() => updateProjectCapacities()}
        >
          Save Updates
        </LoadingButton>
      </Box>
      <Snackbar
        open={total > capacity}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Total capacity is greater than the allocated capacity!
        </Alert>
      </Snackbar>
    </section>
  );
};

export default ProjectsGrid;
