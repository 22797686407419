import {
  ICreateTestSuiteRequest,
  IGenerateTestRequestRequest,
  IGetEndpointForOperationRequest,
  IGetRequestObjectRequest,
  IListFieldVocabsRequest,
  IListRequestIdsForContextRequest,
  IListTestCasesWithRunDetailsRequest,
  IListTestSuitesRequest,
  IListTestInvocationResultsRequest,
  IListTestInvocationResultsResponse,
  ISaveTestRequest,
  RunTestsRequest,
  IUpdateTestCaseStatusRequest,
  IGenerateTestRequestResponse,
  IListSuggestedAssertionsRequest,
  IListSuggestedAssertionsResponse,
  IListSuggestedVariablesRequest,
  IListSuggestedVariablesResponse,
  IListRequestTemplatesRequest,
  IListRequestTemplatesResponse,
  IGetAssertionHashRequest,
  IGetAwareApiTestRequest,
  IGetAwareApiTestResponse,
  IGetGeneratedScriptRequest,
  IListScriptsRequest,
  IListTestCasesRequest,
  IListTestStepsFromTestRequest,
  IEvaluateAssertionsRequest,
  IGetGeneratedScriptResponse,
  UpdateLinkedScenariosForTestRequest,
  UpdateLinkedScenariosForTestResponse,
  ListLinkedScenariosForTestRequest,
  ListLinkedScenariosForTestResponse,
  IGenerateUiScriptRequest,
  RunTestsResponse,
  ListParameterizedTestRunsRequest,
  ListParameterizedTestRunsResponse,
  GenerateApiTestsRequest,
  GenerateApiTestsResponse,
} from "../../test-studio/models";
import client from "../client";

const Test = {
  getGeneratedScript: async (
    request: IGetGeneratedScriptRequest
  ): Promise<IGetGeneratedScriptResponse> => {
    try {
      const response = await client.post<IGetGeneratedScriptResponse>(
        "/test/get_generated_script",
        request
      );
      return response.data;
    } catch (error) {
      // Throw the error to let the caller handle it
      throw error;
    }
  },
  evaluateAssertions: async (
    request: IEvaluateAssertionsRequest
  ) => {
    const response = await client.post(
      "/test/evaluate_assertions",
      request
    );
    return response.data;
  },

  generateApiTests: async (request: GenerateApiTestsRequest): Promise<GenerateApiTestsResponse> => {
    const response = await client.post("/test/generate_api_tests", request);
    return response.data;
  },
  updateLinkedScenariosForTest: async (request: UpdateLinkedScenariosForTestRequest): Promise<UpdateLinkedScenariosForTestResponse> => {
    const response = await client.post("/test/update_linked_scenarios_for_test", request);
    return response.data;
  },
  listLinkedScenariosForTest: async (request: ListLinkedScenariosForTestRequest): Promise<ListLinkedScenariosForTestResponse> => {
    const response = await client.post("/test/list_linked_scenarios_for_test", request);
    return response.data;
  },
  listRequestIdsForContext: async (
    request: IListRequestIdsForContextRequest
  ) => {
    const response = await client.post(
      "/test/list_request_ids_for_context",
      request
    );
    return response.data;
  },
  getRequestObject: async (request: IGetRequestObjectRequest) => {
    const response = await client.post("/test/get_request_object", request);
    return response.data;
  },
  generateUiScript: async (request: IGenerateUiScriptRequest) => {
    const response = await client.post("/test/generate_ui_script", request);
    return response.data;
  },
  getAssertionHash: async (request: IGetAssertionHashRequest) => {
    const response = await client.post("/test/get_assertion_hash", request);
    return response.data;
  },
  generateTestRequest: async (request: IGenerateTestRequestRequest) => {
    const response = await client.post("/test/generate_test_request", request);
    return response.data as IGenerateTestRequestResponse;
  },
  getAwareApiTest: async (request: IGetAwareApiTestRequest) => {
    const response = await client.post("/test/get_aware_api_test", request);
    return response.data as IGetAwareApiTestResponse;
  },
  updateTestCaseStatus: async (request: IUpdateTestCaseStatusRequest) => {
    const response = await client.post(
      "/test/update_test_case_status",
      request
    );
    return response.data;
  },
  listFieldVocabs: async (request: IListFieldVocabsRequest) => {
    const response = await client.post("/test/list_field_vocabs", request);
    return response.data;
  },
  listScripts: async (request: IListScriptsRequest) => {
    const response = await client.post("/admin/list_scripts", request);
    return response.data;
  },
  getEndpointsForOperation: async (
    request: IGetEndpointForOperationRequest
  ) => {
    const response = await client.post(
      "/test/get_endpoint_for_operation",
      request
    );
    return {
      environment: request.environment,
      endpoints: response.data.endpoints as string[],
    };
  },
  runTest: async (request: RunTestsRequest): Promise<RunTestsResponse> => {
    const response = await client.post("test/run_tests", request);
    return response.data;
  },
  saveTest: async (request: ISaveTestRequest) => {
    const response = await client.post("test/upsert_aware_api_test", request);
    return response.data;
  },
  listParameterizedTestRuns: async (request: ListParameterizedTestRunsRequest): Promise<ListParameterizedTestRunsResponse> => {
    const response = await client.post("test/list_parameterized_test_runs", request);
    return response.data;
  },
  listTestSuites: async (request: IListTestSuitesRequest) => {
    const response = await client.post("test/list_test_suites", request);
    return response.data;
  },
  listTestCases: async (request: IListTestCasesRequest) => {
    const response = await client.post("test/list_test_cases", request);
    return response.data;
  },
  listTestStepsFromTest: async (request: IListTestStepsFromTestRequest) => {
    const response = await client.post("test/list_test_steps_from_test", request);
    return response.data;
  },
  listTestCasesWithRunDetails: async (
    request: IListTestCasesWithRunDetailsRequest
  ) => {
    const response = await client.post(
      "test/list_test_cases_with_run_details",
      request
    );
    return response.data;
  },
  listTestInvocationResults: async (
    request: IListTestInvocationResultsRequest
  ): Promise<IListTestInvocationResultsResponse> => {
    const response = await client.post(
      "test/list_test_invocation_results",
      request
    );
    return response.data;
  },
  listSuggestedAssertions: async (
    request: IListSuggestedAssertionsRequest
  ): Promise<IListSuggestedAssertionsResponse> => {
    const response = await client.post(
      "test/list_suggested_assertions",
      request
    );
    return response.data;
  },
  listRequestTemplates: async (
    request: IListRequestTemplatesRequest
  ): Promise<IListRequestTemplatesResponse> => {
    const response = await client.post(
      "admin/list_request_templates",
      request
    );
    return response.data;
  },
  listSuggestedVariables: async (
    request: IListSuggestedVariablesRequest
  ): Promise<IListSuggestedVariablesResponse> => {
    const response = await client.post(
      "test/list_suggested_variables",
      request
    );
    return response.data;
  },
  createTestSuite: async (request: ICreateTestSuiteRequest) => {
    const response = await client.post("test/create_test_suite", request);
    return response.data;
  },
};

export default Test;
