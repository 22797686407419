import React, { forwardRef, useImperativeHandle, useRef, RefObject, useState, useEffect } from "react";
import { IStep, TreeLoadState } from "../models";
import { List, ThemeProvider } from "@mui/material";
import TestStep, { TestStepRef } from "./TestStep";
import theme from "../../common/theme";

interface TestStepsListProps {
    steps: IStep[];
    selectedIndex: number;
    onAddButtonClick?: (index: number) => void;
    onDeleteButtonClick?: (index: number) => void;
    onSelectStep: (index: number) => void;
    highlightUptoTimestamp: number;
    opacityWhenNonHighlighted: number;
    onSelectPrefix: (stepId: string, pathPrefix: string) => void;
}

export interface TestStepsListRef {
    setChildNodesMap: (stepId: string, callTreeLoadingState: TreeLoadState, rootPrefix: string, nodes: { [pathPrefix: string]: string[] }, nodeStatus: { [pathPrefix: string]: string }) => void;
}

const TestStepsList = forwardRef<TestStepsListRef, TestStepsListProps>(({
    steps,
    selectedIndex,
    onAddButtonClick,
    onDeleteButtonClick,
    onSelectStep,
    highlightUptoTimestamp,
    opacityWhenNonHighlighted,
    onSelectPrefix,
}, ref) => {
    const stepRefs = useRef<{ [key: string]: RefObject<TestStepRef> }>({});
    const [highlightedSteps, setHighlightedSteps] = useState<{ [key: string]: boolean }>({});

    useImperativeHandle(ref, () => ({
        setChildNodesMap: (stepId: string, callTreeLoadingState: TreeLoadState, rootPrefix: string, nodes: { [pathPrefix: string]: string[] }, nodeStatus: { [pathPrefix: string]: string }) => {
            if (stepRefs.current[stepId] && stepRefs.current[stepId].current) {
                stepRefs.current[stepId].current!.setChildNodesMap(callTreeLoadingState, rootPrefix, nodes,nodeStatus);
            }
        }
    }));

    useEffect(() => {
        steps.forEach((step) => {
            const shouldHighlight = step.timestamp && step.timestamp <= highlightUptoTimestamp;
            if (shouldHighlight && !highlightedSteps[step.id]) {
                setHighlightedSteps((prev) => ({ ...prev, [step.id]: true }));
            }
        });
    }, [steps, highlightUptoTimestamp]);

    return (
        <ThemeProvider theme={theme}>
            <List sx={{ marginTop: "10px" }}>
                {steps?.map((step, index) => {
                    const stepRef = React.createRef<TestStepRef>();
                    const shouldHighlight = (step.timestamp && step.timestamp <= highlightUptoTimestamp) || false;
                    stepRefs.current[step.id] = stepRef;
                    return (
                        <TestStep
                            ref={stepRef}
                            key={step.id}
                            step={step}
                            index={index}
                            isSelected={index === selectedIndex}
                            shouldHighlight={shouldHighlight}
                            opacity={shouldHighlight ? 1.0 : opacityWhenNonHighlighted}
                            onAddButtonClick={onAddButtonClick}
                            onDeleteButtonClick={onDeleteButtonClick}
                            onSelectStep={onSelectStep}
                            onSelectPrefix={(pathPrefix) => onSelectPrefix(step.id, pathPrefix)}
                            firstTimeHighlight={highlightedSteps[step.id]}
                        />
                    );
                })}
            </List>
        </ThemeProvider>
    );
});

export default TestStepsList;
