import { Box, FormControl, MenuItem, TextField } from "@mui/material";
import ProjectSettings from './ProjectSettings';
import { useAppDispatch } from '../../app/hooks';
import { setProjectId } from './projectSettingsSlice';
import { getProjects } from '../../api/services/User';
import { useSnackbar } from "../../hooks/useSnackbar";
import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../common/theme";
import LocalStorageManager from "../../common/LocalStorageManager";
import { setSelectedProject } from "../../features/commonSlice";
import { useAppSelector } from '../../app/hooks';

const ProjectDropDown: React.FC<{ selectedProjectId: string | undefined; onProjectSelect: (event: React.ChangeEvent<HTMLInputElement>) => void }> = ({ selectedProjectId, onProjectSelect }) => {
  const [projects, setProjects] = useState<{ id: string, name: string }[]>([]);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchProjects() {
      try {
        const projectsRes = await getProjects.getProjects();
        if (!projectsRes.error) {
          setProjects(projectsRes.data.projects);
        } else {
          showSnackbar("Something went wrong when fetching projects", "error");
        }
      } catch (error) {
        showSnackbar("Something went wrong when fetching projects", "error");
      }
    }

    fetchProjects();
  }, [showSnackbar]);

  return (
    <FormControl style={{ marginTop: '10px', marginBottom: '20px', width: "75em" }}>
      <TextField
        id="select-project"
        label={!selectedProjectId ? "Select a Project" : "Selected Project"}
        select
        onChange={onProjectSelect}
        value={projects.length === 0 ? "" : selectedProjectId || ""}
        fullWidth // Ensures the TextField uses the full width of its container
      >
        {projects?.map(project => (
          <MenuItem key={project.id} value={project.id}>
            {project.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};


const ProjectSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector((state) => state.common.selectedProject);

  const handleProjectSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = event.target.value;
    dispatch(setSelectedProject(projectId));
    dispatch(setProjectId(projectId));
    LocalStorageManager.setProjectId(projectId);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', height: "100vh", flexDirection: 'column', alignItems: 'center', padding: '20px', bgcolor: "white" }}>
        <ProjectDropDown selectedProjectId={selectedProject} onProjectSelect={handleProjectSelect} />
        {selectedProject && <ProjectSettings projectId={selectedProject} />}
      </Box>
    </ThemeProvider>

  );
};

export default ProjectSelection;