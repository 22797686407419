import {
  ContextType,
  FieldConfig,
  FieldDataType,
  GeneratorType,
  HTTPMessageSection,
  IGenerator,
  IVariable,
  IOperationContextualVariableRecordConfig,
  PayloadLocator,
  VariableRecordConfig,
  VariableValueLocator,
  IGeneratorKV,
} from "./models";

export const getFieldConfig = (generator: IGenerator) => {
  let fieldConfig: FieldConfig | undefined;

  switch (generator.type) {
    case GeneratorType.FREEFORM:
      fieldConfig = {
        variableExpression: {
          fieldType: FieldDataType.STRING,
          expression: generator.value,
        },
      };
      break;

    case GeneratorType.SCRIPT:
      fieldConfig = {
        scriptConfig: {
          script: generator.value,
          nickname: generator.displayValue,
        },
      };
      break;

    case GeneratorType.VOCABULORY:
      fieldConfig = {
        vocabConfig: {
          numberOfChoices: 1,
          options: generator.value,
          nickname: generator.displayValue,
        },
      };
      break;

    default:
      fieldConfig = undefined;
      console.error("Unknown generator type");
      break;
  }

  return fieldConfig;
};

export const getFields = (generators: IGeneratorKV) => {
  let fields: { [key: string]: FieldConfig } = {};

  generators &&
    Object.entries(generators).forEach(([key, generator]) => {
      const fieldConfig = getFieldConfig(generator);
      if (fieldConfig) {
        fields[key] = fieldConfig;
      }
    });

  return fields;
};

const getVariableValueLocator = (variable: IVariable): VariableValueLocator => {
  let ret: VariableValueLocator = {};
  if (variable.section == HTTPMessageSection.HEADER) {
    ret.headerKey = variable.locator;
  } else if (variable.section == HTTPMessageSection.BODY) {
    // TODO This needs to be handled for other body types as well.
    ret.bodyValueLocator = { jsonFieldPath: variable.locator };
  } else if (variable.section == HTTPMessageSection.PATH_PARAMS) {
    ret.templateParamKey = variable.locator;
  } else if (variable.section == HTTPMessageSection.QUERY_PARAMS) {
    ret.queryParamKey = variable.locator;
  }
  return ret;
};

export const getOperationContextualVariables = (variables: IVariable[]): IOperationContextualVariableRecordConfig[] => {
  // TODO This needs to be handled for other body types as well.
  return variables
    ?.filter((variable) => variable.operationLocator)
    .map((variable) => {
      return {
        operationLocator: variable.operationLocator!,
        payloadLocator: getPayloadLocator(variable.contextType),
        config: {
          variableName: variable.name,
          locator: getVariableValueLocator(variable),
        },
      };
    });
};

export const getPayloadLocator = (contextType: ContextType): PayloadLocator => {
  if (contextType == ContextType.REQUEST) {
    return PayloadLocator.REQUEST;
  } else if (contextType == ContextType.RESPONSE) {
    return PayloadLocator.RESPONSE;
  } else {
    return PayloadLocator.ATTRIBUTES;
  }
};

export const getContextType = (contextType: PayloadLocator): ContextType => {
  if (contextType == PayloadLocator.REQUEST) {
    return ContextType.REQUEST;
  } else if (contextType == PayloadLocator.RESPONSE) {
    return ContextType.RESPONSE;
  } else {
    return ContextType.ATTRIBUTES;
  }
};

export const getOperationNonContextualVariables = (
  variables: IVariable[],
  contextType: ContextType
): VariableRecordConfig[] => {
  return variables
    ?.filter((variable) => variable.contextType == contextType && !variable.operationLocator)
    .map((variable) => {
      return {
        variableName: variable.name,
        locator: getVariableValueLocator(variable),
      };
    });
};

export const getRequestConfig = (
  headerGenerators: IGeneratorKV,
  bodyGenerators: IGeneratorKV,
  queryParamGenerators: IGeneratorKV,
  pathParamGenerators: IGeneratorKV,
  httpMethod: string,
  variables: IVariable[]
) => {
  console.log("Query Parms gens:", queryParamGenerators);
  let val = {
    headerConfig: {
      headerFields: getFields(headerGenerators),
    },
    jsonRequestBodyConfig: {
      fieldConfigs: getFields(bodyGenerators),
    },
    httpMethod: httpMethod,
    queryPathParams: getFields(queryParamGenerators),
    templatePathParams: getFields(pathParamGenerators),
    variableRecordConfigs: getOperationNonContextualVariables(variables, ContextType.REQUEST),
  };
  console.log("REt value:", val);
  return val;
};
