import React, { useState, useEffect } from "react";
import {
  Select,
  DatePicker,
  Button,
  Table,
  Dropdown,
  Menu,
  message,
  Tag,
  Modal,
} from "antd";
import "rrweb-player/dist/style.css";
import {
  IListSessionRecordingsRequest,
  IListTracesForRecordedSessionRequest,
  IListTracesForRecordedSessionResponse,
  SessionDetail,
  SessionPersistenceLevel,
} from "../models/sessions";
import SessionEvents, {
  listSessionUsers,
  listSessionRecordings,
  markSessionAsPermanent,
} from "../api/services/SessionEvents";
import {
  listEnvironments,
  DataPresenceState,
  ProjectDataType,
} from "../api/services/User";
import { getProjects, getProjectHasData } from "../api/services/User";
import dayjs, { Dayjs } from "dayjs";
import LocalStorageManager from "../common/LocalStorageManager";
import { Chip, IconButton, Paper } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { ThemeColors } from "../styles/light-colors";
import { ThemeProvider } from "@mui/material";
import theme from "../common/theme";
import "../styles/SessionFinder.css";
import GettingStartedBox from "./GettingStartedBox";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { formatTimestamp, getDurationString } from "../util/Timestamps";
import { QualifiedOperationWithStatus } from "../api/services/Common";
import styled from "styled-components";
import { Test } from "../api";
import { createSteps } from "../util/StepContextUtils";
import SessionStorageManager from "../common/SessionStorageManager";
import { generateUniqueID } from "../util";
import { getVersionedUrl } from "../common/Util";
import LinkIcon from '@mui/icons-material/Link';
import { filterToDisplayString, getAttributeLocatorDisplayString, getDisplayValue, getOperationLocatorDisplayString, toDisplayString } from "../util/TraceUtils";
import { setSelectedProject } from "../features/commonSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";

const { Option } = Select;
const { RangePicker } = DatePicker;

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

interface Project {
  id: string;
  name: string;
}

const PlayIcon = styled(PlayCircleOutlineIcon)`
  &:hover {
    color: var(--secondary-cta-color);
  }
`;


const SessionFinder: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [environment, setEnvironment] = useState<string>("QA");
  const [availableEnvironments, setAvailableEnvironments] = useState<string[]>(
    []
  );
  const [timeWindow, setTimeWindow] = useState<[Dayjs, Dayjs] | null>([
    dayjs().subtract(1, "hour"),
    dayjs(),
  ]);
  const [users, setUsers] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | undefined>();
  const [sessions, setSessions] = useState<SessionDetail[]>([]);
  const [dataPresenceState, setDataPresenceState] = useState<DataPresenceState>(
    DataPresenceState.HAS_DATA
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [contextRequest, setContextRequest] = useState<IListSessionRecordingsRequest | undefined>();
  const selectedProject = useAppSelector((state) => state.common.selectedProject);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const contextUid: string | null = searchParams.get("uid");
    if (contextUid) {
      let contextRequest: IListSessionRecordingsRequest | undefined = SessionStorageManager.getSessionFindContext(contextUid);
      if (contextRequest) {
        setContextRequest(contextRequest);
        doSearch(contextRequest);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getProjects.getProjects();
        setProjects(response.data.projects);
        if (response.data.projects?.length > 0) {
          handleProjectChange(response.data.projects[0]?.id);
        } else {
          let orgUserRole = LocalStorageManager.getOrgUserRole();
          if (orgUserRole == 'ORG_VIEWER_ROLE') {
            // If is an org viewer, and there are no projects for the user, they cant create any - so ask to contact admin.
            Modal.info({
              title: 'Contact your org admin',
              content: 'It seems you are not part of any project in your organization. Please contact your organizations\' admin to add you to one to proceed.',
              onOk() { },
            });
          } else {
            navigate(getVersionedUrl("/admin-settings"), {
              state: undefined,
              replace: true,
            })
          }
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  const handleProjectChange = async (projectId: string) => {
    await dispatch(setSelectedProject(projectId));

    try {
      const environmentsResponse = await listEnvironments.listEnvironments({});
      const availableEnvironments = environmentsResponse.environments || [];
      setAvailableEnvironments(availableEnvironments);

      // Update the environment to the first value in the list
      if (availableEnvironments.length > 0) {
        setEnvironment(availableEnvironments[0]);
      }

      const dataPresenceResponse = await getProjectHasData.getProjectHasData({
        dataType: ProjectDataType.SESSION_DATA,
      });
      setDataPresenceState(dataPresenceResponse.dataPresenceState);
    } catch (error) {
      console.error(
        "Error fetching environments or data presence state:",
        error
      );
      setAvailableEnvironments([]);
      setDataPresenceState(DataPresenceState.NO_DATA);
    }
  };

  const handleEnvironmentChange = (env: string) => {
    setEnvironment(env);
  };

  const handleTimeWindowChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    if (dates) {
      const [start, end] = dates.map((date) => (date ? date : dayjs(null)));
      setTimeWindow(ensureValidTimeWindow([start, end]));
    } else {
      setTimeWindow(null);
    }
  };

  const ensureValidTimeWindow = (
    timeWindow: [Dayjs | null, Dayjs | null]
  ): [Dayjs, Dayjs] | null => {
    if (timeWindow.every((date) => date !== null)) {
      return timeWindow as [Dayjs, Dayjs];
    }
    return null;
  };

  const convertDayjsToRFC3339 = (time: Dayjs): string => {
    return time.toISOString();
  };

  const fetchUsers = async () => {
    if (!selectedProject || !environment || !timeWindow) return;
    setUsers([]);
    try {
      const response = await listSessionUsers.listSessionUsers({
        environment,
        time_window: {
          fixed_window: {
            start_time: convertDayjsToRFC3339(timeWindow[0]),
            end_time: convertDayjsToRFC3339(timeWindow[1]),
          },
        },
      });
      if (!response.userIds) {
        setUsers([""]);
      } else {
        setUsers(response.userIds);
        setSelectedUser(response.userIds[0]); // Set the selected user here
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = async () => {
    if (!timeWindow || !environment) return;
    setIsLoading(true);
    let req: IListSessionRecordingsRequest = {
      user_id: selectedUser ?? "",
      time_window: {
        fixed_window: {
          start_time: convertDayjsToRFC3339(timeWindow[0]),
          end_time: convertDayjsToRFC3339(timeWindow[1]),
        },
      },
      environment: environment,
    };
    await doSearch(req);
  };

  const doSearch = async (request: IListSessionRecordingsRequest) => {
    try {
      const response = await listSessionRecordings.listSessionRecordings(request);
      setIsLoading(false);
      setSessions(response.sessions);
    } catch (error) {
      console.error("Error fetching sessions:", error);
      setIsLoading(false);
    }
  }

  const handleCopyDirectLink = async (sessionId: string) => {
    const url = `${BASE_APP_URL}/replay?session_id=${sessionId}&project_id=${selectedProject}`;
    await markSessionAsPermanent.markSessionAsPermanent({ session_id: sessionId });

    // Update the session's sessionPersistenceLevel to PERMANENT
    setSessions(prevSessions =>
      prevSessions?.map(session =>
        session.sessionRecordingTrackingId === sessionId
          ? { ...session, sessionPersistenceLevel: SessionPersistenceLevel.PERMANENT }
          : session
      )
    );

    navigator.clipboard
      .writeText(url)
      .then(() => {
        message.success("Permalink created & copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };


  const handleOpenInTestStudio = async (sessionId: string) => {
    let request: IListTracesForRecordedSessionRequest = {
      session_recording_tracking_id: sessionId,
    };
    let response: IListTracesForRecordedSessionResponse =
      await SessionEvents.listTracesForRecordedSession(request);
    const uid = generateUniqueID();
    SessionStorageManager.setTestStudioSteps(
      uid,
      createSteps(response.traces)
    );
    window.open("/test-studio?uid=" + uid + "&project_id=" + selectedProject, "_blank");
  };

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Session ID</div>,
      dataIndex: "sessionRecordingTrackingId",
      key: "sessionRecordingTrackingId",
      width: 300,
    },
    {
      title: <div style={{ textAlign: "center" }}>Time</div>,
      dataIndex: "startTime",
      key: "startTime",
      width: 150,
      render: (text: string, record: SessionDetail) => getDurationString(record.durationMins) + " ( " + formatTimestamp(text) + " )",
    },
    {
      title: <div style={{ textAlign: "center" }}>Play</div>,
      key: "view",
      width: 100,
      render: (text: string, record: SessionDetail) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link
            to={`/replay?session_id=${record.sessionRecordingTrackingId}&project_id=${selectedProject}`}
            target="_blank"
          >
            <PlayIcon />
          </Link>
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Permalink</div>,
      key: "Link",
      width: 150,
      render: (text: string, record: SessionDetail) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            onClick={() =>
              handleCopyDirectLink(record.sessionRecordingTrackingId)
            }
          >
            <LinkIcon sx={{ color: record.sessionPersistenceLevel == SessionPersistenceLevel.PERMANENT ? "blue" : "var(--light-color)" }} />
          </IconButton>
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Executed Steps</div>,
      dataIndex: "entryOperations",
      key: "entryOperations",
      render: (operations: QualifiedOperationWithStatus[]) => (
        <>
          {operations.map((op) => (
            <Chip
              key={`${op.operation.resourceName}-${op.operation.operationName}`}
              label={op.operation.operationName}
              style={{
                marginRight: "4px",
                marginTop: "6px",
                borderRadius: "6px",
                borderColor: op.status !== "STATUS_CODE_OK" ? "var(--error-color)" : "var(--success-color)",
                color: op.status !== "STATUS_CODE_OK" ? "var(--error-color)" : "var(--success-color)",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            />
          ))}
        </>
      ),
    },
  ];

  const getChipText = (req: IListSessionRecordingsRequest): string => {
    let text: string = "";
    const maxLength = 200;

    try {
      if (req?.context) {
        if (req.context.attribute) {
          text += getAttributeLocatorDisplayString(req.context.attribute) + " = " + getDisplayValue(req.context.attribValue);
        }
        if (req.query) {
          if (req.query.time_window) {
            text += ", during " + toDisplayString(req.query.time_window);
          }
          if (req.query.filters) {
            text += ", (" + req.query.filters.map(filterToDisplayString).join(', ') + ")";
          }
        }
        if (text.length > maxLength) {
          return text.slice(0, maxLength - 3) + "...";
        }
        return text;
      }
    } catch (error) {
      return "External Context";
    }
    return "External Context";
  };


  return (
    <div
      style={{
        paddingTop: "10px",
        height: "100vh",
      }}
    >
      <ThemeProvider theme={theme}>
        <div id="test-filter-panel">
          {contextRequest ? (
            <div className="flex-row">
              <Chip onDelete={() => setContextRequest(undefined)} label={getChipText(contextRequest)} sx={{ backgroundColor: "var(--secondary-cta-color)", color: "var(--dark-color)" }} />
            </div>) :
            (<><div className="flex-row">
              <Select
                style={{ flexGrow: 1, marginRight: "10px" }}
                placeholder="Select Project"
                onChange={handleProjectChange}
                value={selectedProject}
              >
                {projects?.map((project) => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ flexGrow: 1, marginRight: "10px" }}
                placeholder="Environment"
                onChange={handleEnvironmentChange}
                value={environment}
              >
                {availableEnvironments?.map((env) => (
                  <Option key={env} value={env}>
                    {env}
                  </Option>
                ))}
              </Select>
              <RangePicker
                style={{ flexGrow: 2, marginRight: "2px" }}
                onChange={handleTimeWindowChange}
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                value={timeWindow}
              />
              <Button
                className="time-button"
                onClick={() =>
                  setTimeWindow([dayjs().subtract(1, "hour"), dayjs()])
                }
              >
                1H
              </Button>
              <Button
                className="time-button"
                onClick={() =>
                  setTimeWindow([dayjs().subtract(1, "day"), dayjs()])
                }
              >
                1D
              </Button>
              <Button
                className="search-button"
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={fetchUsers}
              >
                Find Users
              </Button>
            </div>
              <div className="flex-row">
                <Select
                  style={{ flexGrow: 1, marginRight: "10px" }}
                  placeholder="Select User"
                  onChange={(value) => setSelectedUser(value)}
                  value={selectedUser}
                >
                  {users?.map((user) => (
                    <Option key={user} value={user}>
                      {user === "" ? "<No user id set>" : user}
                    </Option>
                  ))}
                </Select>
                <Button
                  className="search-button"
                  type="primary"
                  onClick={handleSearch}
                  disabled={users.length === 0}
                >
                  Search
                </Button>
              </div></>)}
        </div>
        {dataPresenceState === DataPresenceState.NO_DATA ? (
          <GettingStartedBox />
        ) : (
          <Paper id="testcase-container">
            <Table
              columns={columns}
              dataSource={sessions}
              rowKey="sessionRecordingTrackingId"
              pagination={false}
              loading={isLoading}
              locale={{ emptyText: "No results found" }} // Set the empty text message
            />
          </Paper>
        )}
      </ThemeProvider>
    </div>
  );
};

export default SessionFinder;
