import { FC, Suspense, useEffect, useState } from "react";

import beautify from "beautify";
import { IGeneratorKV, PlainBodyType } from "../models";
import ComponentLoader from "../../common/ComponentLoader";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import Beautify from "ace-builds/src-noconflict/ext-beautify";
import ace from "ace-builds/src-noconflict/ace";
import { getGeneratorLabel } from "../../util/MessageUtils";
import "./RawEditorReadOnly.css";
// Import worker URLs using file-loader
// eslint-disable-next-line import/no-webpack-loader-syntax
import jsonWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-json";
// eslint-disable-next-line import/no-webpack-loader-syntax
import xmlWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-xml";
// eslint-disable-next-line import/no-webpack-loader-syntax
import htmlWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-html";

// Configure Ace Editor to use the worker URLs
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);
ace.config.setModuleUrl("ace/mode/xml_worker", xmlWorkerUrl);
ace.config.setModuleUrl("ace/mode/html_worker", htmlWorkerUrl);

interface RawEditorProps {
  bodyString: string;
  setBodyString: (body: string) => void;
  type: PlainBodyType;
  isReadOnly: boolean;
  isRequestGenerator: boolean;
  generators: IGeneratorKV;
  setHasErrors: (hasErrors: boolean) => void;
  onBlur: () => void;
}

const RawEditor: FC<RawEditorProps> = ({
  bodyString,
  setBodyString,
  type,
  isReadOnly,
  isRequestGenerator,
  generators,
  setHasErrors,
  onBlur,
}) => {
  const format = (body: string) => {
    return type && type != "text" ? beautify(body, { format: type }) : body;
  };

  const handleValidation = (annotations: any[]) => {
    const hasErrors = annotations.some((annotation) => annotation.type === "error" || annotation.type === "warning");
    setHasErrors(hasErrors);
  };

  //const [body, setBody] = useState(() => format(bodyString));
  const [mode, setMode] = useState<PlainBodyType | null>(null);

  useEffect(() => {
    const loadAceMode = async () => {
      switch (type) {
        case "json":
          await import("ace-builds/src-noconflict/mode-json");
          await import("ace-builds/src-noconflict/worker-json");
          break;
        case "xml":
          await import("ace-builds/src-noconflict/mode-xml");
          await import("ace-builds/src-noconflict/worker-xml");
          break;
        case "html":
          await import("ace-builds/src-noconflict/mode-html");
          await import("ace-builds/src-noconflict/worker-html");
          break;
        default:
          await import("ace-builds/src-noconflict/mode-text");
          break;
      }
      setMode(type);
    };

    loadAceMode();
  }, [type]);

  return (
    <Suspense fallback={<ComponentLoader />}>
      <AceEditor
        className={isReadOnly ? "ace-editor-readonly" : ""}
        name={"editor"}
        theme={"github"}
        fontSize={14}
        commands={Beautify.commands}
        readOnly={!isRequestGenerator || isReadOnly}
        width="inherit !important"
        height="inherit !important"
        editorProps={{
          $blockScrolling: true,
        }}
        setOptions={{
          useWorker: true, // Enabled workers to use built-in validation
        }}
        mode={mode ?? "text"}
        value={isRequestGenerator ? (isReadOnly ? getGeneratorLabel(generators["$"]) : bodyString) : bodyString}
        onChange={(value) => {
          //setBody(value);
          setBodyString(value);
        }}
        onValidate={handleValidation}
        onBlur={onBlur}
      />
    </Suspense>
  );
};

export default RawEditor;
