import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Project } from "../../api";
import { useSnackbar } from "../../hooks/useSnackbar";

import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import USER_ROLES from "./Constants/UserRoles";

const UsersGrid = ({ projectUsers, project, removeUser }) => {
  const { showSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [savingUserRoles, setSavingUserRoles] = useState(false);

  useEffect(() => {
    setUsers(projectUsers);
  }, [projectUsers]);

  const onUserRoleChangeHandler = (event) => {
    let updatedUsers = users?.map((user) => {
      if (user.userInfo.userId === selectedUser.userInfo.userId) {
        return {
          ...user,
          config: {
            ...user.config,
            role: event.target.value,
          },
        };
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  const onOpenHandler = (user) => {
    setSelectedUser(user);
  };

  const onCloseHandler = () => {
    setSelectedUser(null);
  };

  const updateUserRoles = async () => {
    const request = {
      project_id: project.projectId,
      user_roles_mappings: getUserRoleMappings(),
    };

    setSavingUserRoles(true);
    const res = await Project.updateUserRoles(request);
    if (!res.error) {
      setSavingUserRoles(false);
      showSnackbar("User roles updated successfully!", "success");
    } else {
      console.error(res.error);
      setSavingUserRoles(false);
      showSnackbar("Updating user roles failed", "error");
    }
  };

  const getUserRoleMappings = () => {
    return users?.map((user) => {
      return {
        user_id: user.userInfo.userId,
        role: user.config.role,
      };
    });
  };

  return (
    <section>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>User Email</strong>
              </TableCell>
              <TableCell width={160}>
                <strong>User Role</strong>
              </TableCell>
              <TableCell width={160} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user.userInfo.userId}>
                <TableCell>{user.userInfo.email}</TableCell>
                <TableCell>
                  <Select
                    displayEmpty
                    value={user.config.role}
                    fullWidth
                    onOpen={() => onOpenHandler(user)}
                    onClose={onCloseHandler}
                    onChange={onUserRoleChangeHandler}
                  >
                    <MenuItem value={USER_ROLES.PROJECT_VIEWER.value}>
                      {USER_ROLES.PROJECT_VIEWER.label}
                    </MenuItem>
                    <MenuItem value={USER_ROLES.PROJECT_EDITOR.value}>
                      {USER_ROLES.PROJECT_EDITOR.label}
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="error"
                    onClick={() => removeUser(user.userInfo.userId)}
                  >
                    <PersonRemoveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" py={1}>
        <LoadingButton
          loading={savingUserRoles}
          variant="contained"
          disableElevation
          onClick={() => updateUserRoles()}
        >
          Save Changes
        </LoadingButton>
      </Box>
    </section>
  );
};

export default UsersGrid;
