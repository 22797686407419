const PROJECT_ID_STORAGE_KEY = "projectId";
const USER_STORAGE_KEY = "user";
const TOKEN_STORAGE_KEY = "token";
const DETAILED_USER_INFO_KEY = "detailedUserInfo";


const LocalStorageManager = {
  clear() {
    localStorage.clear();
  },

  setUser(user) {
    localStorage.setItem(USER_STORAGE_KEY, user);
  },

  removeUser() {
    localStorage.removeItem(USER_STORAGE_KEY);
  },

  getDetailedUserInfo() {
    let storedUserDetail = localStorage.getItem(DETAILED_USER_INFO_KEY) + "";
    return JSON.parse(storedUserDetail);
  },

  getOrganizationId() {
    return this.getDetailedUserInfo().userInfo?.organizationId ?? "";
  },

  getOrganizationStatus() {
    return this.getDetailedUserInfo().orgStatus?? "ACTIVE_ORG";
  },

  getEmailVerificationStatus() {
    return this.getDetailedUserInfo().emailVerificationStatus ?? "UNKNOWN_EMAIL_VERIFICATION_STATUS";
  },

  setDetailedUserInfo(value) {
    localStorage.setItem(DETAILED_USER_INFO_KEY, value);
  },

  setToken(value) {
    localStorage.setItem(TOKEN_STORAGE_KEY, value);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  },

  getToken() {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  },

  setProjectId(value) {
    localStorage.setItem(PROJECT_ID_STORAGE_KEY, value);
  },

  getProjectId() {
    let projectId = localStorage.getItem(PROJECT_ID_STORAGE_KEY);
    if (!projectId) {
      return "";
    }
    return projectId;
  },

  setItem(key, value) {
    localStorage.setItem(key, value);
  },

  getItem(key) {
    return localStorage.getItem(key);
  },

  getProjectConfigMap() {
    const userInfo = JSON.parse(localStorage.getItem("detailedUserInfo"));
    if (userInfo) {
      return userInfo.projectConfigMap;
    }
  },

  getOrgUserRole() {
    const userInfo = JSON.parse(localStorage.getItem("detailedUserInfo"));
    if (userInfo) {
      return userInfo.userOrgRole;
    }
  },
};

export default LocalStorageManager;
