import React, { useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { CaretDownOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import { Dropdown, Mentions, MenuProps } from "antd";
import GeneratorTextField from "./GeneratorTextField";
import {
  CONTENT_TYPE,
  GeneratorType,
  HTTPMessageSection,
  IDrawerContext,
  IGenerator,
  IGeneratorKV,
  IVariable,
} from "../models";
import { isEmpty } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IKVTable {
  menu?: {
    props: MenuProps;
    handleOpen: (currentMenuContext: { open: boolean; context: IDrawerContext }) => void;
  };
  enabled: boolean;
  messageKV: { [key: string]: string };
  generatorKV: IGeneratorKV;
  removeGenerator?: (section: HTTPMessageSection, key: string) => void;
  setGenerator?: (key: string, section: HTTPMessageSection, generator: IGenerator) => void;
  variables: IVariable[];
  section: HTTPMessageSection;
}

const KVTable: React.FC<IKVTable> = ({
  menu,
  enabled,
  messageKV,
  generatorKV,
  removeGenerator,
  setGenerator,
  variables,
  section,
}) => {
  const keys = Object.keys(isEmpty(generatorKV) ? messageKV : generatorKV);
  const [newKV, setNewKV] = useState({
    key: "",
    value: "",
  });

  const addNewKV = () => {
    setGenerator!(newKV.key, section, {
      type: GeneratorType.FREEFORM,
      value: newKV.value,
      displayValue: newKV.value,
    });
    setNewKV({
      key: "",
      value: "",
    });
  };

  return (
    <Box>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {keys?.map((key) => {
              return (
                <TableRow
                  key={key}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell width="170em">
                    <Typography>{key}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
                      {!isEmpty(menu) && key != CONTENT_TYPE && (
                        <Dropdown
                          menu={menu.props}
                          onOpenChange={(open) =>
                            menu.handleOpen!({
                              open,
                              context: {
                                section: section,
                                key: key,
                                value: generatorKV[key]?.value ?? messageKV[key],
                                headerKey: key,
                                modelKey: key,
                              },
                            })
                          }
                        >
                          <IconButton>
                            <CaretDownOutlined style={{ fontSize: "20px" }} />
                          </IconButton>
                        </Dropdown>
                      )}
                      {!isEmpty(menu) && key == CONTENT_TYPE && (
                        <Tooltip title={"This is auto generated. Check Content type in Body section"}>
                          <IconButton disableRipple>
                            <InfoIcon style={{ fontSize: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {enabled && key != CONTENT_TYPE ? (
                        generatorKV && (
                          <>
                            <GeneratorTextField
                              fieldKey={key}
                              section={section}
                              generator={generatorKV[key]}
                              variables={variables}
                            />
                            <IconButton size="small" onClick={() => removeGenerator!(section, key)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </>
                        )
                      ) : (
                        <Box flexGrow={1}>
                          <Mentions disabled readOnly value={messageKV[key]} />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
            {enabled && (
              <TableRow
                key="new"
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell width="170em">
                  <Box flexGrow={1}>
                    <Mentions
                      placeholder="new key"
                      value={newKV.key}
                      onChange={(key) => setNewKV({ key: key, value: newKV.value })}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
                    <Mentions
                      placeholder="new value"
                      value={newKV.value}
                      onChange={(value) => setNewKV({ key: newKV.key, value: value })}
                    />
                    <IconButton size="small" onClick={addNewKV}>
                      <CheckCircleIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KVTable;
