import {
  BodyValueLocator,
  TestExpression,
  ValueEvaluationModifier,
  TestAssertionOperation,
} from "../test-studio/models";
import Converters from "./Converters";
import {
  getLastOperationName,
  getOperationLocatorDisplayString,
} from "./TraceUtils";

const TestExpressionUtils = {
  getBodyValueLocatorText(locator: BodyValueLocator) {
    if ("jsonFieldPath" in locator) {
      const parts = locator.jsonFieldPath!.split(".");
      if (parts.length > 2) {
        return `$...${parts.slice(-2).join(".")}`;
      }
      return locator.jsonFieldPath;
    } else if ("formDataKey" in locator) {
      return locator.formDataKey;
    } else if ("formDataUrlencodedKey" in locator) {
      return locator.formDataUrlencodedKey;
    }
    return "value";
  },

  getAssertionOperationStr(op: TestAssertionOperation) {
    console.log("op ", op);
    if (op == TestAssertionOperation.CONTAINS) {
      return "contains";
    } else if (op == TestAssertionOperation.ENDS_WITH) {
      return "ends with";
    } else if (op == TestAssertionOperation.EQUALS) {
      return "==";
    } else if (op == TestAssertionOperation.GREATER_THAN) {
      return ">";
    } else if (op == TestAssertionOperation.LESS_THAN) {
      return "<";
    } else if (op == TestAssertionOperation.IS_NOT_NULL) {
      return "is not null";
    } else if (op == TestAssertionOperation.IS_NULL) {
      return "is null";
    } else if (op == TestAssertionOperation.NOT_EQUALS) {
      return "!=";
    } else if (op == TestAssertionOperation.STARTS_WITH) {
      return "starts with";
    } else if (op == TestAssertionOperation.IS_EMPTY) {
      return "is empty";
    } else if (op == TestAssertionOperation.IS_NOT_EMPTY) {
      return "is not empty";
    }
    return "eq";
  },

  getChipTextPrefix(expression: TestExpression) {
    const stepName = expression.valueLocator?.stepName;
    const locator = expression.valueLocator;
    let chipPrefix = `${stepName}`;
    if (locator?.operation?.pathPrefix) {
      chipPrefix += getOperationLocatorDisplayString(locator.operation);
    }
    return chipPrefix;
  },

  getChipText(expression: TestExpression) {
    if (!expression) {
      return "";
    }

    if (expression.variable) {
      return `{{${expression.variable}}}`;
    } else if (expression.fixedValues) {
      if (expression.fixedValues.singleValue) {
        return `${Converters.typedValueToString(
          expression.fixedValues.singleValue
        )}`;
      }
      // TODO Handle list
      return "";
    } else if (expression.valueLocator) {
      const evaluationModifier = expression.valueLocator.evaluationModifier;
      const locator = expression.valueLocator;
      let chipText = "";
      if (locator.requestValueLocator) {
        const chipPrefix = this.getChipTextPrefix(expression);
        const requestValueLocator = locator.requestValueLocator;
        if (requestValueLocator.headerKey) {
          chipText = `${chipPrefix}.request.${requestValueLocator.headerKey}`;
        } else if (requestValueLocator.queryParamKey) {
          chipText = `${chipPrefix}.request.${requestValueLocator.queryParamKey}`;
        } else if (requestValueLocator.templateParamKey) {
          chipText = `${chipPrefix}.request.${requestValueLocator.templateParamKey}`;
        } else if (requestValueLocator.bodyValueLocator) {
          chipText = `${chipPrefix}.request.${this.getBodyValueLocatorText(
            requestValueLocator.bodyValueLocator
          )}`;
        }
      } else if (locator.responseValueLocator) {
        const chipPrefix = this.getChipTextPrefix(expression);
        const responseValueLocator = locator.responseValueLocator;
        if (responseValueLocator.headerKey) {
          chipText = `${chipPrefix}.response.${responseValueLocator.headerKey}`;
        } else if (responseValueLocator.responseCode) {
          chipText = `${chipPrefix}.response.code`;
        } else if (responseValueLocator.bodyValueLocator) {
          chipText = `${chipPrefix}.response.${this.getBodyValueLocatorText(
            responseValueLocator.bodyValueLocator
          )}`;
        }
      }else if(locator.attributeKey){
        const chipPrefix = this.getChipTextPrefix(expression);
        chipText=`${chipPrefix}.attrib.${locator.attributeKey}`;
      }

      if (evaluationModifier === ValueEvaluationModifier.SIZE) {
        chipText = chipText + ".size()";
      }
      return chipText;
      // TODO Attrib locator
    }
    return "";
  },
};
export default TestExpressionUtils;
