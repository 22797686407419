import React, { memo, useEffect, useRef } from "react";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import { eventWithTime } from "@rrweb/types";
import { Box } from "@mui/material";
import { cloneDeep } from "lodash";
import { useAppDispatch } from "../../app/hooks";
import { updateCurrentEvent } from "../../features/replaysSlice";

interface ReplayerProps {
  play: boolean;
  timestamp?: number;
  dimensions: { width: number; height: number };
  events: eventWithTime[];
  areEventsLoading: boolean;
  showController: boolean;
}

const Replayer: React.FC<ReplayerProps> = ({
  play,
  timestamp,
  dimensions,
  events,
  areEventsLoading,
  showController,
}) => {
  const dispatch = useAppDispatch();
  const playerRef = useRef<rrwebPlayer | null>(null);
  const playerWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleEventCast = (event: any) => {
    event && dispatch(updateCurrentEvent(cloneDeep(event as eventWithTime)));
  };

  console.log("Replayer loading...");

  useEffect(() => {
    if (playerWrapperRef.current) {
      if (areEventsLoading) {
        playerWrapperRef.current!.innerHTML = "Recording is loading";
      } else {
        if (events?.length > 1) {
          playerRef.current = new rrwebPlayer({
            target: playerWrapperRef.current,
            props: {
              events: cloneDeep(events),
              width: dimensions.width,
              height: dimensions.height,
              showController: showController,
              autoPlay: false,
            },
          });

          const player = playerRef.current;
          if (player) {
            const replayer = player.getReplayer();

            replayer?.on("event-cast", handleEventCast);

            const offset = timestamp ? timestamp - replayer.getMetaData().startTime : 0;
            player.goto(offset, play);

            return () => {
              replayer.off("event-cast", handleEventCast);
              replayer.destroy();

              const playerWrapperCurrent = playerWrapperRef.current;
              playerWrapperCurrent && (playerWrapperCurrent.innerHTML = "");
            };
          }
        } else {
          //playerWrapperRef.current!.innerHTML = "No recording found";
        }
      }
    }
  }, [events]);

  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      const offset = timestamp ? timestamp - player.getMetaData().startTime : 0;
      player && player.goto(offset, play);
    }
  }, [play]);

  useEffect(() => {
    playerRef.current?.$set(dimensions);
    //playerRef.current?.triggerResize();
  }, [dimensions]);

  return (
    <Box
      ref={playerWrapperRef}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
    />
  );
};

export default memo(Replayer);
