import axios from "axios";
import Firebase from "../auth/Firebase";
import LocalStorageManager from "../common/LocalStorageManager";
import { useHistory } from "react-router-dom";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 60000,
  headers: { Accept: "application/json" },
});

// Add a request interceptor
client.interceptors.request.use(
  async (config) => {
    const token = LocalStorageManager.getToken();
    const projectId = LocalStorageManager.getProjectId();
    // Add the "Authorization" header with the token
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["project-id"] = `${projectId}`;
    }
    console.log('Request:', {
      endpoint: config.url, // Access the endpoint being called
      config: config // Log the entire config object for more details
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    console.log('Received response:', {
      endpoint: response.config.url, // Access the endpoint being responded to
      response: response // Log the entire response object for more details
    });
    return response;
  },
  (error) => {
    console.log('Received error:', {
      endpoint: error.config, // Access the endpoint that caused the error
      error: error // Log the entire error object for more details
    });
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      console.error('Received a 401 error. Redirecting to /signin');
      window.location.href = '/signin'; // Redirect to /signin page
    }
    return Promise.reject(error);
  }
);

export default client;
