import React, { useState, useEffect } from "react";
import { Table, message, Select, Button, Tag, Dropdown } from "antd";
import Scenarios, { UserStory, Persona, Milestone, Sprint, UpsertUserStoryListRequest, Priority, UserStoryByPersona, UserStoryWithTestScenarios, TestScenario, UpsertTestScenarioListRequest, TestScenarioStatus, UserStoryStatus, GetUserStoryResponse, GetTestScenarioResponse, UserStorySimple, TestScenarioSimple } from "../api/services/Scenarios";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "../common/theme";
import { IconButton, Paper, Menu, MenuItem } from "@mui/material";
import UpsertUserStoryDialog from "./UpsertUserStoryDialog"; // Updated import
import { useSearchParams } from "react-router-dom";
import { CheckCircle, MoreVert } from "@mui/icons-material";
import ManageTestScenariosDialog from "./ManageTestScenariosDialog";
import SuggestUserStoriesDialog from "./SuggestUserStoriesDialog";
import EditTestScenarioDialog from "./EditTestScenarioDialog";
import { ITestNameWithId } from "../test-studio/models";
import { setSelectedProject } from "../features/commonSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";

const { Option } = Select;

interface PersonaMap {
    [key: number]: string;
}

interface MilestoneMap {
    [key: number]: string;
}

interface SprintMap {
    [key: number]: string;
}

const UserStoryViewer: React.FC = () => {
    const [userStoriesByPersona, setUserStoriesByPersona] = useState<Record<number, UserStoryByPersona>>({});
    const [personas, setPersonas] = useState<PersonaMap>({});
    const [milestones, setMilestones] = useState<MilestoneMap>({});
    const [epicId, setEpicId] = useState<number | undefined>();
    const [sprints, setSprints] = useState<SprintMap>({});
    const [selectedSprint, setSelectedSprint] = useState<number | undefined>(undefined);
    const [selectedMilestone, setSelectedMilestone] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [suggestUserStoriesDialogOpen, setSuggestUserStoriesDialogOpen] = useState(false);
    const [editScenarioDialogOpen, setEditScenarioDialogOpen] = useState(false);
    const [isManageTestScenariosDialogOpen, setIsManageTestScenariosDialogOpen] = useState<boolean>(false);
    const [currentUserStoryWithScenarios, setCurrentUserStoryWithScenarios] = useState<UserStoryWithTestScenarios | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [testLinkAnchorEl, setTestLinkAnchorEl] = useState<null | HTMLElement>(null);
    const [personaAnchorEl, setPersonaAnchorEl] = useState<null | HTMLElement>(null);
    const [scenarioAnchorEl, setScenarioAnchorEl] = useState<null | HTMLElement>(null);
    const [menuRecord, setMenuRecord] = useState<GenericRecord | null>(null);
    const [personaMenuRecord, setPersonaMenuRecord] = useState<GenericRecord | null>(null);
    const [scenarioMenuRecord, setScenarioMenuRecord] = useState<GenericRecord | null>(null);
    const [editingScenario, setEditingScenario] = useState<TestScenario | undefined>();
    const [editingUserStory, setEditingUserStory] = useState<UserStory | undefined>();
    const [selectedPersonaId, setSelectedPersonaId] = useState<number | undefined>();
    const selectedProject = useAppSelector((state) => state.common.selectedProject);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const epicIdFromParams = Number(searchParams.get("epic_id"));
        const selectedProject = searchParams.get("project_id");
        setEpicId(epicIdFromParams);
        dispatch(setSelectedProject(selectedProject!));
    }, [searchParams]);

    useEffect(() => {
        const fetchData = async () => {
            if (epicId !== undefined) {
                await fetchPersonas();
                await fetchMilestones();
                await fetchSprints();
            }
        };

        fetchData();
    }, [epicId]);

    useEffect(() => {
        if (personas) {
            fetchUserStories();
        }
    }, [personas])

    const fetchUserStories = async (sprintId?: number, milestoneId?: number) => {
        setIsLoading(true);
        try {
            const request: any = { epicId: Number(epicId) };
            if (sprintId) request.sprintId = sprintId;
            if (milestoneId) request.milestoneId = milestoneId;

            const response = await Scenarios.listUserStories(request);

            // Transform response to match required format
            const userStoriesByPersona = Object.fromEntries(
                Object.entries(personas).map(([personaId]) => [
                    Number(personaId),
                    response.userStoriesByPersona?.[Number(personaId)] || [] // Ensure an empty array for personas without user stories
                ])
            );

            setUserStoriesByPersona(userStoriesByPersona);
        } catch (error) {
            console.error("Error fetching user stories:", error);
            message.error("Failed to fetch user stories");
        }
        setIsLoading(false);
    };

    const fetchPersonas = async () => {
        try {
            const response = await Scenarios.listPersonas({});
            const personaMap: PersonaMap = {};
            response.personas?.forEach((persona: Persona) => {
                personaMap[persona.id!] = persona.title!;
            });
            setPersonas(personaMap);
        } catch (error) {
            console.error("Error fetching personas:", error);
            message.error("Failed to fetch personas");
        }
    };

    const fetchMilestones = async () => {
        try {
            const response = await Scenarios.listMilestones({});
            const milestoneMap: MilestoneMap = {};
            response.milestones?.forEach((milestone: Milestone) => {
                milestoneMap[milestone.id] = milestone.name || "Unnamed Milestone";
            });
            setMilestones(milestoneMap);
        } catch (error) {
            console.error("Error fetching milestones:", error);
            message.error("Failed to fetch milestones");
        }
    };

    const fetchSprints = async () => {
        try {
            const response = await Scenarios.listSprints({});
            const sprintMap: SprintMap = {};
            response.sprints?.forEach((sprint: Sprint) => {
                sprintMap[sprint.id] = sprint.name || "Unnamed Sprint";
            });
            setSprints(sprintMap);
        } catch (error) {
            console.error("Error fetching sprints:", error);
            message.error("Failed to fetch sprints");
        }
    };

    const handleSearch = () => {
        fetchUserStories(selectedSprint, selectedMilestone);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditingUserStory(undefined); // Clear editing user story on close
    };

    const handleEditScenarioCloseDialog = () => {
        setEditScenarioDialogOpen(false);
        setEditingScenario(undefined); // Clear editing scenario on close
    };

    const handleCloseSuggestUserStoriesDialog = (saved: boolean) => {
        setSuggestUserStoriesDialogOpen(false);
        setPersonaMenuRecord(null); // Clear editing user story on close
        if (saved) {
            fetchUserStories();
        }
    };

    const handleSaveOfTestScenarios = async (updatedTestScenarios: TestScenario[]) => {
        try {
            let response = await Scenarios.upsertTestScenarioList({ scenarios: updatedTestScenarios });
        } catch (error: any) {
            if (error.response?.data?.responseHeader?.errorMessage) {
                message.error(error.response?.data?.responseHeader?.errorMessage)
            }
        }
        fetchUserStories(selectedSprint, selectedMilestone);
    };

    const handleSaveUserStory = async (userStory: UserStory) => {
        const request: UpsertUserStoryListRequest = {
            userStories: [{
                id: userStory.id,
                title: userStory.title,
                description: userStory.description,
                epicId: Number(userStory.epicId),
                milestoneId: Number(userStory.milestoneId),
                sprintId: Number(userStory.sprintId),
                status: userStory.status,
                primaryPersonaId: Number(userStory.primaryPersonaId),
                priority: userStory.priority ?? Priority.P1
            }]
        };
        await Scenarios.upsertUserStoryList(request);
        handleCloseDialog();
        fetchUserStories(selectedSprint, selectedMilestone);
    };

    const handleSaveTestScenario = async (testScenario: TestScenario) => {
        const request: UpsertTestScenarioListRequest = {
            scenarios: [{
                id: testScenario.id,
                title: testScenario.title,
                description: testScenario.description,
                userStoryId: testScenario.userStoryId,
                priority: testScenario.priority ?? Priority.P1,
                status: testScenario.status ?? TestScenarioStatus.ACTIVE_TEST_SCENARIO,
            }]
        };
        await Scenarios.upsertTestScenarioList(request);
        handleCloseDialog();
    };

    const handleOpenDialog = () => {
        setEditingUserStory(undefined); // Clear editing user story
        setDialogOpen(true);
    };

    const openManageTestScenarios = (record: GenericRecord) => {
        console.log('Action triggered for:', record);

        // Extract user story data from the record
        const userStory: UserStorySimple = {
            id: record.id,
            title: record.title,
            priority: record.priority,
            milestoneId: record.milestoneId,
            sprintId: record.sprintId,
        };

        // Extract test scenarios from the children of the record
        const testScenarios: TestScenarioSimple[] = record.children?.filter(child => child.recordType === 'scenario').map(child => ({
            id: child.id,
            title: child.title,
            status: child.status,
            priority: child.priority,
        })) || [];

        // Construct UserStoryWithTestScenarios object
        const userStoryWithScenarios: UserStoryWithTestScenarios = {
            userStory,
            testScenarios,
        };

        // Update state and open the dialog
        setCurrentUserStoryWithScenarios(userStoryWithScenarios);
        setIsManageTestScenariosDialogOpen(true);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, record: GenericRecord) => {
        setAnchorEl(event.currentTarget);
        setMenuRecord(record);
    };

    const handleScenarioMenuClick = (event: React.MouseEvent<HTMLElement>, record: GenericRecord) => {
        setScenarioAnchorEl(event.currentTarget);
        setScenarioMenuRecord(record);
    };

    const handlePersonaMenuClick = (event: React.MouseEvent<HTMLElement>, record: GenericRecord) => {
        setPersonaAnchorEl(event.currentTarget);
        setPersonaMenuRecord(record);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuRecord(null);
    };

    const handleScenarioMenuClose = () => {
        setScenarioAnchorEl(null);
        setScenarioMenuRecord(null);
    };

    const handlePersonaMenuClose = () => {
        setPersonaAnchorEl(null);
        setPersonaMenuRecord(null);
    };

    const handleManageTestScenarios = () => {
        if (menuRecord) {
            openManageTestScenarios(menuRecord);
        }
        handleMenuClose();
    };

    const handleViewEditUserStory = async () => {
        if (menuRecord) {
            let response: GetUserStoryResponse = await Scenarios.getUserStory({ userStoryId: menuRecord?.id! });
            let userStory: UserStory = response.userStory;
            setEditingUserStory(userStory);
            setDialogOpen(true);
        }
        handleMenuClose();
    };

    const handleViewEditScenario = async () => {
        if (scenarioMenuRecord) {
            let response: GetTestScenarioResponse = await Scenarios.getTestScenario({ scenarioId: scenarioMenuRecord.id });
            // Set the editing user story and open the dialog
            const scenario: TestScenario = response.scenario;
            setEditingScenario(scenario);
            setEditScenarioDialogOpen(true);
        }
        handleMenuClose();
    };
    const handleOpenSuggestUserStories = () => {
        if (personaMenuRecord) {
            setSelectedPersonaId(personaMenuRecord.id);
            setSuggestUserStoriesDialogOpen(true);
        }
        handlePersonaMenuClose();
    };

    const getTestScenarioStatusDisplay = (record: GenericRecord) => {

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            console.log("Clicked")
            setTestLinkAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setTestLinkAnchorEl(null);
        };

        const handleMenuItemClick = (testId: string) => {
            window.open("/test-studio?test_id=" + testId + (selectedProject ? "&project_id=" + selectedProject : ""), "_blank");
            handleClose();
        };

        return record.linkedTestCases && record.linkedTestCases.length > 0 ? (
            <>
                <IconButton onClick={handleClick} style={{ padding: 0 }}>
                    <CheckCircle style={{ color: '#9E9E9E' }} />
                </IconButton>
                <Menu
                    anchorEl={testLinkAnchorEl}
                    open={Boolean(testLinkAnchorEl)}
                    onClose={handleClose}
                >
                    {record.linkedTestCases?.map((testCase) => (
                        <MenuItem
                            key={testCase.testId}
                            onClick={() => handleMenuItemClick(testCase.testId)}
                        >
                            {testCase.suite} - {testCase.name}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        ) : (
            <></>
        );
    };


    const getUserStoryStatusDisplayString = (status: any) => {

        let userStoryStatus = status as UserStoryStatus;

        switch (userStoryStatus) {
            case UserStoryStatus.IN_SCOPING:
                return <Tag color="#4B0082" style={{ color: 'white' }}>In Scoping</Tag>;  // Darker purple
            case UserStoryStatus.IN_DEVELOPMENT:
                return <Tag color="#00008B" style={{ color: 'white' }}>In Development</Tag>;  // Darker blue
            case UserStoryStatus.IN_TESTING:
                return <Tag color="#FF8C00" style={{ color: 'white' }}>In Testing</Tag>;  // Dark orange
            case UserStoryStatus.COMPLETED:
                return <Tag color="#006400" style={{ color: 'white' }}>Completed</Tag>;
            case UserStoryStatus.COMPLETED:
                return <Tag color="#006400" style={{ color: 'white' }}>Completed</Tag>;  // Dark green
            default:
                return <Tag color="#696969" style={{ color: 'white' }}>Active</Tag>;  // Dark gray
        }
    };

    const columns: ColumnsType<GenericRecord> = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: "auto",
            render: (text: any, record: GenericRecord) => text
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            width: "80px",
            render: (text: any, record: GenericRecord) => {
                return (
                    <>
                        {record.recordType === "userstory" && (
                            <>
                                <IconButton
                                    onClick={(event) => handleMenuClick(event, record)}
                                >
                                    <MoreVert />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleManageTestScenarios}>
                                        Manage Test Scenarios
                                    </MenuItem>
                                    <MenuItem onClick={handleViewEditUserStory}>
                                        View/Edit User Story
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                        {record.recordType === "persona" && (
                            <>
                                <IconButton
                                    onClick={(event) => handlePersonaMenuClick(event, record)}
                                >
                                    <MoreVert />
                                </IconButton>
                                <Menu
                                    anchorEl={personaAnchorEl}
                                    open={Boolean(personaAnchorEl)}
                                    onClose={handlePersonaMenuClose}
                                >
                                    <MenuItem onClick={handleOpenSuggestUserStories}>
                                        Suggest User Stories...
                                    </MenuItem>
                                </Menu>
                            </>)}
                        {record.recordType === "scenario" && (
                            <>
                                <IconButton
                                    onClick={(event) => handleScenarioMenuClick(event, record)}
                                >
                                    <MoreVert />
                                </IconButton>
                                <Menu
                                    anchorEl={scenarioAnchorEl}
                                    open={Boolean(scenarioAnchorEl)}
                                    onClose={handleScenarioMenuClose}
                                >
                                    <MenuItem onClick={handleViewEditScenario}>
                                        View / Edit Test Scenario...
                                    </MenuItem>
                                </Menu>
                            </>)}                    </>
                )
            },
        }, {
            title: "Priority",
            dataIndex: "priority",
            key: "priority",
            width: "100px",
            render: (priority: Priority, record: GenericRecord) => {
                if (record.recordType == "persona") {
                    return (<></>);
                }
                return (
                    priority === Priority.UNKNOWN_PRIORITY ?
                        <div></div> :
                        <Tag
                            style={{
                                fontWeight: 'bold',
                            }}
                        >{priority}</Tag>
                );
            },
        }
        , {
            title: "Status",
            dataIndex: "Status",
            key: "status",
            width: "100px",
            render: (text: any, record: GenericRecord) => {
                return (
                    <>
                        {record.recordType === "userstory" && (
                            <>{getUserStoryStatusDisplayString(record.status)}</>
                        )}
                        {record.recordType === "scenario" && (
                            <>{getTestScenarioStatusDisplay(record)}</>
                        )}
                        {record.recordType === "persona" && (
                            <></>
                        )}
                    </>
                )
            },
        }, {
            title: "Sprint",
            dataIndex: "sprintId",
            key: "sprintId",
            width: "100px",
            render: (sprintId: number) => {
                const sprintName = sprints[sprintId];
                return sprintName ? <Tag>{sprintName}</Tag> : <div></div>;
            },
        },
        {
            title: "Milestone",
            dataIndex: "milestoneId",
            key: "milestoneId",
            width: "100px",
            render: (milestoneId: number) => {
                const milestoneName = milestones[milestoneId];
                return milestoneName ? <Tag>{milestoneName}</Tag> : <div></div>;
            },
        },
    ];

    interface GenericRecord {
        key: React.ReactNode;
        title: string;
        id: number;
        userStoryId: number; // only present in test scenario records
        priority: Priority;
        milestoneId: number;
        sprintId: number;
        recordType: string;
        status: any;
        primaryPersonaId?: number; // Add this if you are using it
        linkedTestCases?: ITestNameWithId[];
        children?: GenericRecord[];
    }

    const personaData: GenericRecord[] = Object.entries(userStoriesByPersona)
        .filter(([personaId, data]) => personaId && Number(personaId) !== 0)
        .map(([personaId, data]) => ({
            key: personaId,
            title: `As a ${personas[Number(personaId)] || "Unknown Persona"}`,
            description: "",
            id: Number(personaId),
            priority: Priority.P1,
            milestoneId: 0,
            sprintId: 0,
            userStoryId: 0,
            status: "",
            linkedTestCases: [],
            recordType: "persona",
            children: data.userStories?.map((record) => ({
                key: record.userStory.id || '',
                title: record.userStory.title || '',
                id: record.userStory.id || 0,
                userStoryId: 0,
                priority: record.userStory.priority || Priority.P1,
                milestoneId: record.userStory.milestoneId || 0,
                sprintId: record.userStory.sprintId || 0,
                recordType: "userstory",
                status: record.userStory.status,
                primaryPersonaId: record.userStory.primaryPersonaId,
                linkedTestCases: [],
                children: record.testScenarios?.map((scenario) => ({
                    key: scenario.id || '',
                    title: scenario.title || '',
                    id: scenario.id || 0,
                    userStoryId: scenario.userStoryId || 0,
                    priority: scenario.priority || 0,
                    milestoneId: 0,
                    status: scenario.status,
                    linkedTestCases: scenario.linkedTestCases,
                    sprintId: 0,
                    recordType: "scenario"
                }))
            }))
        }));


    return (
        <div style={{ paddingTop: "10px", height: "100vh" }}>
            <ThemeProvider theme={theme}>
                <div id="test-filter-panel">
                    <div className="flex-row">
                        <Select
                            placeholder="Select Sprint"
                            style={{ width: "100%", marginRight: "10px" }}
                            onChange={(value: number) => setSelectedSprint(value)}
                            allowClear
                        >
                            {Object.entries(sprints).map(([id, name]) => (
                                <Option key={id} value={Number(id)}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            placeholder="Select Milestone"
                            style={{ width: "100%", marginRight: "10px" }}
                            onChange={(value: number) => setSelectedMilestone(value)}
                            allowClear
                        >
                            {Object.entries(milestones).map(([id, name]) => (
                                <Option key={id} value={Number(id)}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={handleSearch} className="search-button">
                            Search
                        </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "10px" }}>
                        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                            <Button className="secondary-button" onClick={handleOpenDialog}>
                                Add New User Story
                            </Button>
                        </div>
                    </div>
                </div>
                <Paper id="table-container">
                    <Table
                        columns={columns}
                        dataSource={personaData}
                        loading={isLoading}
                        rowKey="key"
                    />
                </Paper>
                <UpsertUserStoryDialog // Updated dialog component
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onSave={handleSaveUserStory}
                    personas={personas}
                    milestones={milestones}
                    epicId={epicId!}
                    sprints={sprints}
                    userStoryToEdit={editingUserStory} // Pass user story for editing
                />
                <EditTestScenarioDialog
                    open={editScenarioDialogOpen}
                    onClose={handleEditScenarioCloseDialog}
                    onSave={handleSaveTestScenario}
                    testScenarioToEdit={editingScenario} // Pass the test scenario for editing
                />
                <SuggestUserStoriesDialog // Updated dialog component
                    open={suggestUserStoriesDialogOpen}
                    onClose={handleCloseSuggestUserStoriesDialog}
                    personaId={selectedPersonaId}
                    epicId={epicId}
                />
                {currentUserStoryWithScenarios && (
                    <ManageTestScenariosDialog
                        open={isManageTestScenariosDialogOpen}
                        onClose={() => setIsManageTestScenariosDialogOpen(false)}
                        userStoryWithScenarios={currentUserStoryWithScenarios}
                        handleSaveOfScenarios={handleSaveOfTestScenarios}
                    />
                )}
            </ThemeProvider>
        </div>
    );
};

export default UserStoryViewer;