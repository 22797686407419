import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { Grid, Typography } from '@mui/material';
import CopyableTextField from "../../common/CopyableTextField";

const GeneralSettings: React.FC = () => {
  const projectConfig = useAppSelector(state => state.projectSettings.projectConfig);
  const projectId = useAppSelector(state => state.projectSettings.projectId);

  if (!projectConfig) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body1" align="left">
              Project ID
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <CopyableTextField
              value={projectId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body1" align="left">
              Session Record API Key
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <CopyableTextField
              value={projectConfig.sessionRecordApiKey}
            ></CopyableTextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body1" align="left">
              Data API Key
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <CopyableTextField
              value={projectConfig.apiKey}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralSettings;