import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AwareTestType, IAwareApiTest, IListTestCasesWithRunDetailsRequest, IListTestCasesWithRunDetailsResponse, IListTestSuitesResponse, IStep, ITestAssertion, ITestCaseWithRunData, ITestInvocationResult, ITestNameWithId, IUpdateTestCaseStatusRequest, ListParameterizedTestRunsResponse, MainTab, ParameterizedTestRunData, RunTestsRequest, TestAssertionResult, TestCaseStatus, TestExecutionState, TestSuiteNameAndId, TreeLoadState } from '../test-studio/models';
import { PrefixSummary, PrefixSummaryTree, TraceCollectionSummaryTree } from '../models/overlays';
import SessionEvents from '../api/services/SessionEvents';
import Collectors from '../api/services/Collectors';
import { ITraceDetail } from '../models/sessions';
import { Test } from '../api';
import { AppDispatch, RootState } from "../app/store";

interface TestListState {
    testSuites: TestSuiteNameAndId[];
    selectedTests: ITestNameWithId[];
    selectedSuite: string | undefined;
    testCases: ITestCaseWithRunData[];
    executionStates: { [key: string]: ITestInvocationResult };
    parameterizedRows: { [key: string]: ParameterizedTestRunData[] };
    loadingRows: { [key: string]: boolean };
    visibleExecutionStates: { [key: string]: boolean };
    isLoading: boolean;
}


const initialState: TestListState = {
    testSuites: [],
    selectedTests: [],
    selectedSuite: undefined,
    testCases: [],
    executionStates: {},
    parameterizedRows: {},
    loadingRows: {},
    visibleExecutionStates: {},
    isLoading: false
};

export const selectedTestIds = createSelector(
    [(state: RootState) => state.testList.selectedTests],
    (selectedTests) => selectedTests?.map((test) => test.testId)
);


export const listParameterizedTestRuns = createAsyncThunk(
    'testList/listParameterizedTestRuns',
    async (testId: string) => {
        const response = await Test.listParameterizedTestRuns({ testId });
        return { testId, paramRows: response.parameterizedTestRuns };
    }
);

export const listTestSuites = createAsyncThunk(
    'testList/listTestSuites',
    async (): Promise<IListTestSuitesResponse> => {
        return await Test.listTestSuites({ type: AwareTestType.FS_TEST });
    }
);

export const listTestCasesWithRunDetails = createAsyncThunk(
    'testList/listTestCasesWithRunDetails',
    async (request: IListTestCasesWithRunDetailsRequest): Promise<IListTestCasesWithRunDetailsResponse> => {
        return await Test.listTestCasesWithRunDetails(request);
    }
);

export const updateTestCaseStatus = createAsyncThunk(
    'testList/updateTestCaseStatus',
    async ({ request }: { request: IUpdateTestCaseStatusRequest }, { dispatch, getState }) => {
        return await Test.updateTestCaseStatus(request);
    }
);

export const runTest = createAsyncThunk(
    'testList/runTest',
    async (request: RunTestsRequest) => {
        return await Test.runTest(request);
    }
);

export const runTests = createAsyncThunk<
    void,
    { testCases: ITestNameWithId[], environment: string },
    { dispatch: AppDispatch, state: RootState }
>(
    'testList/runTests',
    async (
        { testCases, environment }: { testCases: ITestNameWithId[]; environment: string },
        { dispatch, getState }
    ) => {
        testCases.forEach((testCase) => {
            const testKey = `${testCase.suite}#${testCase.name}`;
            dispatch(setExecutionStates({
                key: testKey, executionState: {
                    invocationId: '',
                    result: {
                        awareApiTestExecutionResult: {
                            assertionResults: [],
                        },
                    },
                    state: TestExecutionState.UNKNOWN_TEST_EXECUTION_STATE,
                }
            }));
        });

        try {
            const testCaseList = {
                testCases: testCases?.map((testCase) => ({
                    name: testCase.name,
                    suite: testCase.suite,
                })),
                testType: AwareTestType.FS_TEST,
            };

            const response = await Test.runTest({
                environment,
                testCaseList,
            });

            const invocationBatchId = response.invocationBatchId;

            // Show the new columns for the specific test cases
            testCases.forEach((testCase) => {
                const testKey = `${testCase.suite}#${testCase.name}`;
                dispatch(setVisibleExecutionStates({ key: testKey, isVisible: true }));
            });

            // Polling function to get the test execution results
            const pollResults = async () => {
                try {
                    const resultResponse = await Test.listTestInvocationResults({
                        batchInvocationId: invocationBatchId,
                    });

                    const invocationResults = resultResponse.invocationResults;

                    invocationResults.forEach((invocation) => {
                        const testKey = invocation.testName?.suite + "#" + invocation.testName?.name + (invocation.parameterizedRowString ? "|" + invocation.parameterizedRowString : "");
                        dispatch(setExecutionStates({ key: testKey, executionState: invocation }))
                    });

                    // Continue polling if not all tests are completed
                    if (
                        invocationResults.some(
                            (result) =>
                                result.state !== TestExecutionState.EXCEPTION_IN_TEST_RUN &&
                                result.state !== TestExecutionState.EXECUTION_COMPLETED &&
                                result.state !== TestExecutionState.SKIPPED_EXECUTION
                        )
                    ) {
                        setTimeout(pollResults, 5000);
                    }
                } catch (error) {
                    console.error('Error polling test results:', error);
                }
            };

            pollResults();
        } catch (error) {
            console.error('Error running tests:', error);

            testCases.forEach((testCase) => {
                const testKey = `${testCase.suite}#${testCase.name}`;
                dispatch(
                    setExecutionStates({
                        key: testKey,
                        executionState: {
                            invocationId: '',
                            result: {
                                awareApiTestExecutionResult: {
                                    assertionResults: [],
                                },
                            },
                            state: TestExecutionState.EXCEPTION_IN_TEST_RUN,
                        },
                    })
                );
            });
        }
    }
);

const testListSlice = createSlice({
    name: 'testList',
    initialState,
    reducers: {
        setSelectedTests: (state, action: PayloadAction<ITestNameWithId[]>) => {
            state.selectedTests = action.payload;
        },
        selectTest: (state, action: PayloadAction<ITestNameWithId>) => {
            state.selectedTests = [...state.selectedTests, action.payload];
        },
        deselectTest: (state, action: PayloadAction<ITestNameWithId>) => {
            state.selectedTests = state.selectedTests.filter(
                (test) => test.testId !== action.payload.testId
            );
        },
        setExecutionStates: (
            state,
            action: PayloadAction<{ key: string, executionState: ITestInvocationResult }>
        ) => {
            state.executionStates[action.payload.key] = action.payload.executionState;
        },
        setParameterizedRows: (
            state,
            action: PayloadAction<{ [key: string]: ParameterizedTestRunData[] }>
        ) => {
            state.parameterizedRows = action.payload;
        },
        toggleTestSelection: (
            state, // Replace with your actual state type
            action: PayloadAction<ITestNameWithId>
        ) => {
            const selectedTest = action.payload;
            const index = state.selectedTests.findIndex(test => test.testId === selectedTest.testId);
            if (index >= 0) {
                // If the test is already selected, remove it
                state.selectedTests.splice(index, 1);
            } else {
                // If the test is not selected, add it
                state.selectedTests.push(selectedTest);
            }
        },
        selectAllTests: (state, action: PayloadAction<void>) => {
            state.selectedTests = state.testCases?.map(testCaseWithRunData => testCaseWithRunData.testCase);
        },
        deselectAllTests: (state, action: PayloadAction<void>) => {
            state.selectedTests = [];
        },
        setLoadingRows: (
            state,
            action: PayloadAction<{ testId: string; isLoading: boolean }>
        ) => {
            const { testId, isLoading } = action.payload;
            state.loadingRows[testId] = isLoading;
        },
        setSelectedSuite: (state, action: PayloadAction<string>) => {
            state.selectedSuite = action.payload;
        },
        setVisibleExecutionStates: (
            state,
            action: PayloadAction<{ key: string; isVisible: boolean }>
        ) => {
            state.visibleExecutionStates[action.payload.key] = action.payload.isVisible;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(listTestSuites.fulfilled, (state, action) => {
                const response: IListTestSuitesResponse = action.payload;
                state.testSuites = response.testSuites;
            })
            .addCase(listTestSuites.rejected, (state, action) => {
                console.log("Error fetching test suites");
            }).addCase(listTestCasesWithRunDetails.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(listTestCasesWithRunDetails.fulfilled, (state, action) => {
                const response: IListTestCasesWithRunDetailsResponse = action.payload;
                state.isLoading = false;
                if (response.testCases) {
                    state.testCases = response.testCases;
                } else {
                    state.testCases = [];
                }
                state.executionStates = {};
            })
            .addCase(listTestCasesWithRunDetails.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateTestCaseStatus.pending, (state, action) => {
                const { testIds, newStatus } = action.meta.arg.request;
                state.testCases = state.testCases?.map(tc =>
                    testIds.includes(tc.testCase.testId) ? { ...tc, status: newStatus } : tc
                );
            })
            .addCase(updateTestCaseStatus.fulfilled, (state, action) => {
                const { testIds, newStatus } = action.meta.arg.request;
                // Remove the test cases that have been marked as DELETED
                if (newStatus === TestCaseStatus.DELETED) {
                    state.testCases = state.testCases?.filter(tc => !testIds.includes(tc.testCase.testId));
                }
            })
            .addCase(updateTestCaseStatus.rejected, (state, action) => {
            })
            .addCase(listParameterizedTestRuns.pending, (state, action) => {
                const testId = action.meta.arg;
                state.loadingRows[testId] = true;
            })
            .addCase(listParameterizedTestRuns.fulfilled, (state, action) => {
                const { testId, paramRows } = action.payload;
                state.parameterizedRows[testId] = paramRows;
                state.loadingRows[testId] = false;
            })
            .addCase(listParameterizedTestRuns.rejected, (state, action) => {
                const testId = action.meta.arg;
                state.loadingRows[testId] = false;
            })
    }
});

export const {
    setSelectedSuite,
    setSelectedTests,
    selectTest,
    deselectTest,
    selectAllTests,
    deselectAllTests,
    toggleTestSelection,
    setExecutionStates,
    setVisibleExecutionStates,
} = testListSlice.actions;
export default testListSlice.reducer;

