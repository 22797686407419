import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProjectConfig, EnvironmentConfig, OasConfig } from '../../models/config/models';
import ProjectTS, { ResourceConfig } from '../../api/services/ProjectTS';
import { generateUniqueID } from '../../util';
import { RootState } from '../../app/store';

interface Variable {
  id: string;
  name: string;
  value: string;
}

interface Environment {
  variables: Variable[];
}

interface ProjectSettingsState {
  projectId: string | null;
  projectConfig: ProjectConfig;
  resourceConfigs: Record<string, ResourceConfig>;
  tempVariables: { [envKey: string]: Environment };
}

const initialState: ProjectSettingsState = {
  projectId: null,
  resourceConfigs: {},
  projectConfig: {
    entrypointsConfig: undefined,
    environmentConfigs: {},
    timezone: undefined,
    apiKey: undefined,
    sessionRecordApiKey: undefined,
    guardDefaultConfig: undefined,
  },
  tempVariables: {},
};

export const saveProjectConfig = createAsyncThunk(
  'projectSettings/saveProjectConfig',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { projectId, projectConfig } = state.projectSettings;

    if (!projectId) throw new Error('Project ID is missing');

    const response = await ProjectTS.updateProjectConfig({ projectId, projectConfig });
    return response;
  }
);

export const saveResourceConfigs = createAsyncThunk(
  'projectSettings/saveResourceConfigs',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { resourceConfigs } = state.projectSettings;
    const response = await ProjectTS.updateResourceConfigs({ configs: Object.values(resourceConfigs) });
    return response;
  }
);
const projectSettingsSlice = createSlice({
  name: 'projectSettings',
  initialState,
  reducers: {
    setProjectId(state, action: PayloadAction<string>) {
      state.projectId = action.payload;
    },
    setProjectConfig(state, action: PayloadAction<ProjectConfig>) {
      state.projectConfig = action.payload;
      state.tempVariables = Object.keys(state.projectConfig.environmentConfigs ?? []).reduce((acc, envKey) => {
        const envConfig = state.projectConfig.environmentConfigs[envKey];
        acc[envKey] = {
          variables: Object.entries(envConfig.variableOverrides || {}).map(([name, value]) => ({
            id: generateUniqueID(),
            name,
            value,
          })),
        };
        return acc;
      }, {} as { [envKey: string]: Environment });
    },
    addEnvironment(state, action: PayloadAction<string>) {
      const envName = action.payload;
      if (!state.projectConfig.environmentConfigs[envName]) {
        state.projectConfig.environmentConfigs[envName] = {
          variableOverrides: {},
        };
        state.tempVariables[envName] = { variables: [] };
      }
    },
    setEnvironmentVariable(
      state,
      action: PayloadAction<{ envKey: string; variableId: string; name?: string; value: string }>
    ) {
      const { envKey, variableId, name, value } = action.payload;
      const tempEnv = state.tempVariables[envKey];
      if (tempEnv) {
        const variable = tempEnv.variables?.find(v => v.id === variableId);
        if (variable) {
          if (name) {
            const existingVar = tempEnv.variables?.find(v => v.name === name);
            if (existingVar && existingVar.id !== variableId) {
              tempEnv.variables = tempEnv.variables?.filter(v => v.id !== variableId);
              tempEnv.variables.push({ id: variableId, name, value });
            } else {
              variable.name = name;
              variable.value = value;
            }
          } else {
            variable.value = value;
          }
        } else {
          tempEnv.variables.push({ id: variableId, name: name + "", value });
        }
      }
    },
    removeEnvironmentVariable(
      state,
      action: PayloadAction<{ envKey: string; variableId: string }>
    ) {
      const { envKey, variableId } = action.payload;
      const tempEnv = state.tempVariables[envKey];
      if (tempEnv) {
        tempEnv.variables = tempEnv.variables.filter(v => v.id !== variableId);
      }
    },
    updateConfigAndSave(state) {
      const updatedConfig = {
        ...state.projectConfig,
        environmentConfigs: Object.keys(state.tempVariables).reduce((acc, envKey) => {
          const tempEnv = state.tempVariables[envKey];
          acc[envKey] = {
            variableOverrides: tempEnv.variables.reduce((vars, variable) => {
              vars[variable.name] = variable.value;
              return vars;
            }, {} as { [key: string]: string }),
          };
          return acc;
        }, {} as { [envKey: string]: EnvironmentConfig }),
      };
      state.projectConfig = updatedConfig;
    },
    // New reducer for updating templated paths
    updateTemplatedPaths(state, action: PayloadAction<string[]>) {
      if (!state.projectConfig.entrypointsConfig) {
        state.projectConfig.entrypointsConfig = {};
      }
      state.projectConfig.entrypointsConfig.templatedPaths = action.payload;
    },
    updateResourceConfigs(state, action: PayloadAction<Record<string,ResourceConfig>>) {
      state.resourceConfigs = action.payload;
    },
    // New reducer for adding a single templated path
    addTemplatedPath(state, action: PayloadAction<string>) {
      if (!state.projectConfig.entrypointsConfig) {
        state.projectConfig.entrypointsConfig = {};
      }
      const templatedPaths = state.projectConfig.entrypointsConfig.templatedPaths || [];
      if (!templatedPaths.includes(action.payload)) {
        state.projectConfig.entrypointsConfig.templatedPaths = [...templatedPaths, action.payload];
      }
    },
    // New reducer for removing a single templated path
    removeTemplatedPath(state, action: PayloadAction<number>) {
      if (state.projectConfig.entrypointsConfig?.templatedPaths) {
        state.projectConfig.entrypointsConfig.templatedPaths = state.projectConfig.entrypointsConfig.templatedPaths.filter(
          (_, index) => index !== action.payload
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveProjectConfig.fulfilled, (state, action) => {
      // Handle successful update
    });
    builder.addCase(saveProjectConfig.rejected, (state, action) => {
      // Handle update failure
    });
  },
});

export const {
  setProjectId,
  setProjectConfig,
  addEnvironment,
  setEnvironmentVariable,
  removeEnvironmentVariable,
  updateConfigAndSave,
  updateTemplatedPaths,
  updateResourceConfigs,
  addTemplatedPath,
  removeTemplatedPath,
} = projectSettingsSlice.actions;

export default projectSettingsSlice.reducer;