import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import '../styles/homestyles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../common/theme';
import OrganizationPreOobe from '../api/services/OrganizationPreOobe';
import { storeUserInfoInLocalStorage } from '../api/services/User';

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const CreateOrg = () => {
    const [organizationName, setOrganizationName] = useState('');
    const [referredCode, setReferralCode] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isMessageError, setIsMessageError] = useState(false);

    let timeoutId: NodeJS.Timeout | null = null;

    useEffect(() => {
    }, []);



    const handleCreateOrg = async () => {
        const response = await OrganizationPreOobe.addOrganization(organizationName, referredCode);
        if (response.status == 200) {
            setIsMessageError(false);
            setMessage("Organization created successfully");
            const userInfo = await storeUserInfoInLocalStorage();
            window.location.href = BASE_APP_URL + "/admin-settings";
        } else {
            setIsMessageError(true);
            setMessage("An error occured when creating organization ");
        }
    };

    const updateOrganizationName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const orgName: string = e.target.value;
        setOrganizationName(orgName);
    }

    const updateReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const referralCode: string = e.target.value;
        setReferralCode(referralCode);
    }

    let messageColor = "green";
    if (isMessageError) {
        messageColor = "red";
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={'center_ten_padding_top'}>
                <Box
                    component="form"
                    borderRadius={5}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        p: 5,
                        backgroundColor:"white"
                    }}
                    // noValidate
                    // autoComplete="off"
                    border={'2px outset'}
                    boxShadow={'0 0 3px primary, 0 0 5px'}
                // boxShadow={'5px 5px 10px #000000'}
                >
                    <Typography variant='h5' style={{ marginBottom: "10px" }}>Create Organization</Typography>
                    <div>
                        <TextField
                            label="Organization Name"
                            placeholder=''
                            size="small"
                            variant="standard"
                            type='name'
                            value={organizationName}
                            onChange={updateOrganizationName}
                        />
                    </div>
                    <div>
                        <Button sx={{ mt: 3 }} variant="contained" color='primary' onClick={handleCreateOrg} disabled={!organizationName}>
                            Create Organization
                        </Button>
                    </div>
                    <div style={{ color: messageColor }}><h3>{message}</h3></div>
                </Box>
            </div>
        </ThemeProvider>
    );
}

export default CreateOrg;