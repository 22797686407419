import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import React, { useEffect, useState } from "react";
import { Organization } from "../../../api";

const UpgradePlan = ({ open, close, billingSource }) => {
  const [paymentMethodSetupURL, setPaymentMethodSetupURL] = useState();
  const [message, setMessage] = useState('');

  useEffect(() => {
    open && getPaymentMethodSetupURL();
    console.log("Billing source received: ",billingSource);
  }, [open]);

  const getPaymentMethodSetupURL = async () => {
    if (billingSource != "stripe") {
      return;
    }
    const payload = {};

    const res = await Organization.getPaymentMethod(payload);
    if (!res.error) {
      setPaymentMethodSetupURL(res.data.paymentMethodSetupUrl);
    } else {
      console.error(res.error);
      //TODO: Need to handle this error better way
    }
  };

  const redirectToStripe = () => {
    window.location.href = paymentMethodSetupURL;
  };

  const upgradeAwsSubscribedOrgToPremium = async () => {
    const payload = {
      new_tier: "PRO_TIER",
    };
    const res = await Organization.updateTier(payload);
    setMessage("Successfully upgraded to Pro Tier. You will be billed via AWS consolidated billing.")
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const listStyle = {
    width: "100%",
    bgcolor: "background.paper",
  };
  let messageUi = (<h5>{message}</h5>);
  let billingSetupUi = <></>;
  if (billingSource == "aws") {
    billingSetupUi = (<Button variant="contained" onClick={upgradeAwsSubscribedOrgToPremium}>
      Upgrade To Premium (AWS Consolidated Billing)
    </Button>)
  } else {
    billingSetupUi = (<Button variant="contained" onClick={redirectToStripe}>
      Setup Billing
    </Button>)
  }
  return (
    <>
      <Modal open={open} onClose={close}>
        <Box sx={styles}>
          <Box display="flex" alignItems="center" gap={1}>
            <StarsIcon />
            <Typography variant="h5">Upgrade to Premium</Typography>
          </Box>
          <Box>
            <Card sx={{ marginTop: "2rem" }}>
              <CardContent>
                <List
                  sx={listStyle}
                  component="nav"
                  aria-label="mailbox folders"
                >
                  <ListItem>
                    <ListItemText primary="No limitation on number of projects" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="No cap on Users (PAYG)" />
                  </ListItem>
                  <Divider />
                  <ListItem divider>
                    <ListItemText primary="No cap on # Test Runs (PAYG)" />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText primary="No cap on # Session Records (PAYG)" />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText primary="Use Forever" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="SSO Support" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Can Deploy In-House" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="TestChimp Customer Support" />
                  </ListItem>
                  <Divider light />
                </List>
              </CardContent>
            </Card>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            {billingSetupUi}
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            {messageUi}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UpgradePlan;
