import client from "../client";
import FirebaseService from "../../auth/Firebase";
import { Timestamp } from "./Common";
import LocalStorageManager from "../../common/LocalStorageManager";
import axios from 'axios';

interface CreateUserRequest {
  signupUser: (
    email: string,
    preferredName: string,
    password: string,
    emailVerified: string,
    provider: string,
    idamId: string,
    inviteeUserId: string,
    awsMarketplaceToken: string
  ) => Promise<any>;
}

interface GetProjectsRequest {
  getProjects: () => Promise<any>;
}

export type ListResourcesRequest = {
  resourceTypes?: string[];
};

export type ListReleaseVersionsRequest = {
  resourceId: number;
};

export type ListReleaseVersionsResponse = {
  versions?: ReleaseVersion[];
};

export type ReleaseVersion = {
  label: string;
  firstSeenTimestamp: Timestamp;
};

export type ListEnvironmentsRequest = {
  // Intentionally left empty.
};

enum ProjectDataType {
  SESSION_DATA = 1,
}

export type GetProjectHasDataRequest = {
  dataType: ProjectDataType;
};

enum DataPresenceState {
  HAS_DATA = "HAS_DATA",
  NO_DATA = "NO_DATA",
}

export type GetProjectHasDataResponse = {
  state: DataPresenceState;
};


export type ListEnvironmentsResponse = {
  environments?: string[];
};

interface ListResources {
  listResources: (request: ListResourcesRequest) => Promise<any>;
}

interface ListReleaseVersions {
  listReleaseVersions: (request: ListReleaseVersionsRequest) => Promise<any>;
}

interface ListEnvironments {
  listEnvironments: (request: ListEnvironmentsRequest) => Promise<any>;
}

interface GetProjectHasData {
  getProjectHasData: (request: GetProjectHasDataRequest) => Promise<any>;
}


interface UserDetailsResponse {
  userResponse: (email: string, userId: string) => Promise<any>;
}

const listReleaseVersions: ListReleaseVersions = {
  listReleaseVersions: async (request: ListReleaseVersionsRequest) => {
    try {
      let response = await client.post(
        "/collectors/list_release_versions",
        request
      );
      return response.data;
    } catch (error) {
      console.error(`Error calling Collectors.ListReleaseVersions `, error);
      return { error: error };
    }
  },
};


const getProjectHasData: GetProjectHasData = {
  getProjectHasData: async (request: GetProjectHasDataRequest) => {
    try {
      let response = await client.post(
        "/collectors/get_project_has_data",
        request
      );
      return response.data;
    } catch (error) {
      console.error(`Error calling Collectors.GetProjectHasData `, error);
      return { error: error };
    }
  },
};

const listEnvironments: ListEnvironments = {
  listEnvironments: async (request: ListEnvironmentsRequest) => {
    try {
      let response = await client.post(
        "/collectors/list_environments",
        request
      );
      return response.data;
    } catch (error) {
      console.error(`Error calling Collectors.ListReleaseVersions `, error);
      return { error: error };
    }
  },
};

const listResources: ListResources = {
  listResources: async (request: ListResourcesRequest) => {
    try {
      let response = await client.post("/collectors/list_resources", request);
      return response.data;
    } catch (error) {
      console.error(`Error calling Collectors.ListResources `, error);
      return { error: error };
    }
  },
};

const getProjects: GetProjectsRequest = {
  getProjects: async () => {
    try {
      let response = await client.post("/user/get_projects", {});
      console.log("get projects", response);
      return response;
    } catch (error) {
      console.error(`Error calling User.GetProjects `, error);
      return { error: error };
    }
  },
};

const createUser: CreateUserRequest = {
  signupUser: async (
    email,
    preferredName,
    password,
    emailVerified,
    provider,
    idamId,
    inviteeUserId,
    awsMarketplaceToken
  ) => {
    try {
      let response = await client.post("/admin/add_user", {
        email: email,
        name: preferredName,
        password: password,
        email_verified: emailVerified,
        provider: provider,
        idam_id: idamId,
        invitee_user_id: inviteeUserId,
        aws_marketplace_token: awsMarketplaceToken,
      });
      console.log("User created successfully", response);
      return response;
    } catch (error) {
      console.log("Error in add ",error);
      if (axios.isAxiosError(error) && error.response?.data.responseHeader.failureReason) {
        const failureReason = error.response.data.responseHeader.failureReason;
        console.error(`Error calling User.AddUser(${email})`, error);
        return { error: error, failureReason: failureReason };
      } else {
        console.error(`Error calling User.AddUser(${email})`, error);
        return { error: error };
      }
    }
  },
};

const getDetailedUserInfo = {
  userResponse: async (token: String | null) => {
    try {
      let response = await client.post("/user/get_detailed_user_info", {});
      console.log("Response of detaileduserinfo", response);
      return response.data;
    } catch (error) {
      return { error: error };
    }
  },
};

const storeUserInfoInLocalStorage = async () => {
  try {
    const token = await FirebaseService.getToken();
    const userData = await getDetailedUserInfo.userResponse(token);
    LocalStorageManager.setDetailedUserInfo(JSON.stringify(userData));
    return localStorage.getItem("user");
  } catch (error) {
    console.error("Error while storing user data in local storage:", error);
  }
};

export {
  getProjects as getProjects,
  createUser as createUser,
  getDetailedUserInfo as getUser,
  listResources as listResources,
  listReleaseVersions as listReleaseVersions,
  listEnvironments as listEnvironments,
  DataPresenceState, ProjectDataType,
  getProjectHasData as getProjectHasData,
  storeUserInfoInLocalStorage as storeUserInfoInLocalStorage,
};
