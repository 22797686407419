import { createTheme, Theme } from "@mui/material/styles";

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#008080",
    },
  },  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontFamily: 'Roboto, Arial, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Roboto, Arial, sans-serif',
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'Roboto, Arial, sans-serif',
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Roboto Slab, Arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Roboto Slab, Arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
});
