import { Primitive } from "../models/basic/model";
import {
  BoolTypedValue,
  FloatTypedValue,
  IntTypedValue,
  StringTypedValue,
  TypedValue,
  TypedValueOrList,
} from "../models/typed/models";

const Converters = {
  stringToTypedValue(input: string) {
    let result:TypedValue;
    let tempValue: any = Converters.stringToBool(input);
    if (tempValue) {
      result = { boolValue: tempValue };
    } else {
      tempValue = Converters.stringToInt(input);
      if (tempValue) {
        result = { intValue: tempValue };
      } else {
        tempValue = Converters.stringToFloat(input);
        if (tempValue) {
          result = { floatValue: tempValue };
        } else {
          result = { stringValue: input };
        }
      }
    }

    return result;
  },

  typedValueToList(input: TypedValue) {
    return {
      singleValue: input,
    };
  },

  primitiveToTypedValue(input: Primitive) {
    let result: TypedValue;
    switch (typeof input) {
      case "string":
        result = { stringValue: input };
        break;
      case "boolean":
        result = { boolValue: input };
        break;
      case "number":
        result = Number.isInteger(input)
          ? { intValue: input }
          : { floatValue: input };
        break;
    }

    return result;
  },

  typedValueListToString(obj: TypedValueOrList) {
    if (obj.singleValue) {
      return this.typedValueToString(obj.singleValue);
    }
    if (obj.valueList && obj.valueList.value?.length > 0) {
      return obj.valueList.value
        .map(this.typedValueToString.bind(this))
        .join(",");
    }
  },

  typedValueToString(typedValue: TypedValue) {
    if (typedValue.stringValue) {
      return typedValue.stringValue;
    } else if (typedValue.floatValue) {
      return typedValue.floatValue.toString();
    } else if (typedValue.intValue) {
      return typedValue.intValue.toString();
    } else if (typedValue.boolValue) {
      return typedValue.boolValue.toString();
    }
    return "";
  },

  stringToBool(input: string): boolean | undefined {
    const isTrue = input.toLowerCase() === "true";
    const isFalse = input.toLowerCase() === "false";

    if (isTrue || isFalse) {
      return isTrue;
    } else {
      return undefined;
    }
  },

  stringToInt(input: string): number | undefined {
    const value = parseInt(input);

    if (!isNaN(value)) {
      return value;
    } else {
      return undefined;
    }
  },

  stringToFloat(input: string): number | undefined {
    const floatValue = parseFloat(input);

    if (!isNaN(floatValue)) {
      return floatValue;
    } else {
      return undefined;
    }
  },
};

export default Converters;
