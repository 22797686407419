import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, styled, Checkbox } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CheckBox, Google } from '@mui/icons-material';
import '../styles/homestyles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../common/theme';
import { createUser } from '../api/services/User';
import OrganizationPreOobe from '../api/services/OrganizationPreOobe';
import { ResponseData, ResponseHeader } from '../api/Response'
import { UserCredential, getAdditionalUserInfo, AdditionalUserInfo } from 'firebase/auth';
import FirebaseService from '../auth/Firebase'
import { storeUserInfoInLocalStorage } from '../api/services/User';
import { postData } from '../common/WebUtils';
import { getVersionedUrl } from '../common/Util';
import LocalStorageManager from '../common/LocalStorageManager';

const DEMO_PROJECT_ID = process.env.REACT_APP_DEMO_PROJECT_ID;
const DEMO_RESOURCE_ID = process.env.REACT_APP_DEMO_RESOURCE_ID;
const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [awsMarketplaceToken, setAwsMarketplaceToken] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isExploreDemoFlow, setIsExploreDemoFlow] = useState(false);
    const [isInviteFlow, setIsInviteFlow] = useState(false);
    const [password, setPassword] = useState('');
    const [organization, setOrganization] = useState<string>('');
    const [hasOrganization, setHasOrganization] = useState<boolean>(false);

    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [showSignInPrompt, setShowSignInPrompt] = useState(false);
    const [isSignUpButtonEnabled, setIsSignUpButtonEnabled] = useState(false);
    const [inviteeUserId, setInviteeUserId] = useState("");

    let timeoutId: NodeJS.Timeout | null = null;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const flow = urlParams.get('flow');
        const userId = urlParams.get('user_id') + "";
        const token = urlParams.get('token');
        let awsMarketplaceToken = urlParams.get('aws_marketplace_token');
        if (awsMarketplaceToken) {
            awsMarketplaceToken = decodeURIComponent(awsMarketplaceToken);
        }
        if (awsMarketplaceToken !== null && awsMarketplaceToken !== "") {
            setAwsMarketplaceToken(awsMarketplaceToken);
        }
        if (flow == "invite") {
            setIsInviteFlow(true);
            setInviteeUserId(userId);
            postData('admin/verify_user_email', { 'user_id': userId, 'token': token })
                .then(response => {
                    // Check the response status
                    if (response && response.responseHeader && response.responseHeader.status === 'SUCCESS') {
                    } else {
                        setIsErrorMessage(true);
                        setMessage("Unable to verify email address");
                    }
                })
                .catch(error => {
                    console.error(error);
                    setIsErrorMessage(true);
                    setMessage("Unable to verify email address");
                });
        }
        if (flow == "exploredemo") {
            setIsExploreDemoFlow(true);
        }
    }, []);

    useEffect(() => {
        updateSignUpButtonEnableStatus();
    }, []);

    const GoogleSignInButton = styled(Button)({
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            background: '#f1f1f1',
        },
    });
    const GoogleSignInIcon = styled(Google)({
        marginRight: '8px',
    });

    const handleSignUp = async () => {
        const isEmailVerified = "false";
        const provider = "EMAIL";
        const userResponse = await createUser.signupUser(email, preferredName, password, isEmailVerified, provider, "", inviteeUserId, awsMarketplaceToken);
        if (userResponse.error) {
            if (userResponse.failureReason === "USER_FOR_EMAIL_ALREADY_EXISTS") {
                setIsErrorMessage(true);
                setMessage("Email is already registered. Please Sign in instead.");
            } else if (userResponse.failureReason === "DOMAIN_IS_PUBLIC") {
                setIsErrorMessage(true);
                setMessage("Please sign up with your work email address instead.");
            } else {
                setIsErrorMessage(true);
                setMessage("Error signing up user with Google. Make sure you are signing up with your work email.");
            }
        } else {
            setIsErrorMessage(false)
            if (isInviteFlow) {
                setShowSignInPrompt(true);
            } else {
                setIsErrorMessage(false);
                setMessage("Your account is created successfully. Check your mailbox for a verification mail to complete sign up.");
            }
        }
    };

    const handleGoogleSignUp = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithGoogle();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result)
            if (additionalUserInfo?.isNewUser) {
                const currentUser = await FirebaseService.getCurrentUser();
                if (currentUser && 'email' in currentUser && 'displayName' in currentUser) {
                    const currentUserEmail: string = typeof currentUser.email === 'string' ? currentUser.email : '';
                    const currentUserDispalyName: string = typeof currentUser.displayName === 'string' ? currentUser.displayName : '';
                    saveGoogleSignedUpUser(currentUserEmail, currentUserDispalyName, currentUser.emailVerified, currentUser.uid)
                }
            } else {
                setShowSignInPrompt(true);
            }
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to Signup User with Google");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDispalyName: string, emailVerified: boolean, uid: string) => {
        const userResponse = await createUser.signupUser(currentUserEmail, currentUserDispalyName, "", String(emailVerified), "GOOGLE", uid, inviteeUserId, awsMarketplaceToken);
        console.log("google signup response", userResponse);
        if (userResponse.error) {
            if (userResponse.failureReason === "USER_FOR_EMAIL_ALREADY_EXISTS") {
                setIsErrorMessage(true);
                setMessage("Email is already registered. Please Sign in instead.");
            } else if (userResponse.failureReason === "DOMAIN_IS_PUBLIC") {
                setIsErrorMessage(true);
                setMessage("Please sign up with your work email address instead.");
            } else {
                setIsErrorMessage(true);
                setMessage("Error signing up user with Google");
            }
        } else {
            await storeUserInfoAndRedirect(userResponse.data.organizationId);
        }
    }

    const storeUserInfoAndRedirect = async (orgId: string) => {
        let result = await storeUserInfoInLocalStorage();
        let organizationId: string = orgId;
        if (!orgId) {
            organizationId = LocalStorageManager.getOrganizationId();
        }
        let redirectUrl: string = BASE_APP_URL + "";
        if (!organizationId) {
            redirectUrl = BASE_APP_URL + "/createOrg";
        }
        console.log("Redirecting to", redirectUrl);
        window.location.href = redirectUrl;
    };

    const validateEmail = (email: string): boolean => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredEmail: string = e.target.value;
        setEmail(enteredEmail);
        updateSignUpButtonEnableStatus();
    }

    const updatePreferredName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const preferredName: string = e.target.value;
        setPreferredName(preferredName);
        updateSignUpButtonEnableStatus();
    }

    const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password: string = e.target.value;
        setPassword(password);
        updateSignUpButtonEnableStatus();
    }

    const updateSignUpButtonEnableStatus = () => {
        setIsSignUpButtonEnabled(email.length > 0 && password.length > 5 && preferredName.length > 0);
    }



    const handleEmailChange = (e: React.FocusEvent<HTMLInputElement>) => {
        const enteredEmail: string = e.target.value;
        setEmail(enteredEmail);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setIsValidEmail(validateEmail(enteredEmail));

        if (isValidEmail) {
            timeoutId = setTimeout(async () => {
                console.log("entered email: " + enteredEmail);
                await getOrganization(enteredEmail);
            }, 2000); // Delay for 2 seconds
        }
    };

    const getOrganization = async (email: string) => {
        const domain = email.split('@')[1];
        const response = await OrganizationPreOobe.getOrgByDomain(domain)

        if ('error' in response) {
            setOrganization("");
            setHasOrganization(false);
        }
        else {
            const data: ResponseData = response.data;
            console.log("data" + data.responseHeader.status + " " + data.responseHeader.failureReason);
            if (data.responseHeader.status === "FAILURE" && data.responseHeader.failureReason === "UNKNOWN_ORGANIZATION") {
                setOrganization("");
                setHasOrganization(false);
            } else if (data.responseHeader.status == "FAILURE" && data.responseHeader.failureReason == "DOMAIN_IS_PUBLIC") {
                console.log("public domain")
                setOrganization("");
                setHasOrganization(false);
                setIsValidEmail(false);
            } else if (data.responseHeader.status === "SUCCESS") {
                setOrganization(data.name);
                setHasOrganization(true);
            }
        }
    }

    let signinRedirect = "/signin";
    if (isExploreDemoFlow) {
        signinRedirect = signinRedirect + "?flow=exploredemo";
    }
    signinRedirect = getVersionedUrl(signinRedirect);
    let messageColor = "green";
    if (isErrorMessage) {
        messageColor = "red";
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={'center_ten_padding_top'}>
                <Box
                    component="form"
                    borderRadius={5}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        p: 5
                    }}
                    // noValidate
                    // autoComplete="off"
                    border={'2px outset'}
                    boxShadow={'0 0 3px primary, 0 0 5px'}
                    style={{ backgroundColor: "white" }}
                >
                    <Typography variant='h4' style={{ marginBottom: "10px" }}>Sign Up</Typography>
                    <div>
                        <TextField
                            label="Work Email"
                            placeholder='Email address (Work)'
                            error={!isValidEmail}
                            helperText={!isValidEmail ? "Not a valid work mail address" : ""}
                            size="small"
                            variant="standard"
                            type='email'
                            value={email}
                            onBlur={handleEmailChange}
                            onChange={updateEmail}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Preferred Name"
                            size="small"
                            variant="standard"
                            value={preferredName}
                            onChange={updatePreferredName}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Password"
                            type={"password"}
                            size="small"
                            variant="standard"
                            value={password}
                            onChange={updatePassword}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "5px", maxWidth: "20em", color: "gray" }}>
                        <p>
                            By creating your account, you agree to our <a href="https://www.testchimp.io/terms/terms-%26-conditions">Terms of Service</a> and <a href="https://www.testchimp.io/terms/privacy-policy">Privacy Policy</a>
                        </p>
                    </div>
                    <div>
                        <Button sx={{ mt: 3 }} disabled={!isSignUpButtonEnabled} variant="contained" color='primary' onClick={handleSignUp}>
                            Sign up
                        </Button>
                    </div>
                    <div style={{ marginTop: '20px', marginBottom: '10px', color: 'rgba(0, 0, 0, 0.54)' }}>
                        <hr style={{ display: 'inline-block', verticalAlign: 'middle', width: '30%', height: '1px', background: '#000' }} />
                        <Typography style={{ display: 'inline', fontWeight: 'bold' }}> Or </Typography>
                        <hr style={{ display: 'inline-block', verticalAlign: 'middle', width: '30%', height: '1px', background: '#000' }} />
                        <GoogleSignInButton onClick={handleGoogleSignUp}>
                            <GoogleSignInIcon />
                            Continue with Google
                        </GoogleSignInButton>
                    </div>
                    <div>
                        <Button variant="text" sx={{ mt: 1, textTransform: 'none' }} component={Link} to={signinRedirect}>Login instead</Button>
                    </div>
                </Box>
                <div><h3 id='status-message' style={{ color: messageColor, justifyContent: "center", alignItems: "center" }}>{message}</h3></div>
                <div>{showSignInPrompt && <h3 style={{ color: messageColor, justifyContent: "center", alignItems: "center" }}>Your account is created successfully. Please <Link to={signinRedirect}>Sign In</Link> to continue.</h3>}</div>
            </div>
        </ThemeProvider>
    );
}

export default SignUp;