import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Box, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Modal, TextField, Button } from '@mui/material';
import { addEnvironment, setEnvironmentVariable, removeEnvironmentVariable } from './projectSettingsSlice';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from '../../app/hooks';
import { generateUniqueID } from '../../util';

const EnvironmentSettings: React.FC = () => {
  const dispatch = useDispatch();
  const tempVariables = useAppSelector(state => state.projectSettings.tempVariables);
  const [selectedEnv, setSelectedEnv] = useState<string>(Object.keys(tempVariables)[0] || '');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newEnvName, setNewEnvName] = useState<string>('');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewEnvName('');
  };

  const handleAddEnvironment = () => {
    if (newEnvName && !tempVariables[newEnvName]) {
      dispatch(addEnvironment(newEnvName));
      setSelectedEnv(newEnvName);
      handleCloseModal();
    }
  };

  const handleVariableChange = (variableId: string, value: string) => {
    console.log("Changing value", variableId + " " + value);
    const variable = tempVariables[selectedEnv].variables?.find(v => v.id === variableId);
    dispatch(setEnvironmentVariable({ envKey: selectedEnv, variableId, name: variable?.name ?? "", value }));
  };

  const handleVariableRename = (variableId: string, name: string) => {
    console.log("Changing name", variableId + " " + name);
    const value = tempVariables[selectedEnv].variables.find(v => v.id === variableId)?.value || '';
    dispatch(setEnvironmentVariable({ envKey: selectedEnv, variableId, name, value }));
  };

  const handleRemoveVariable = (variableId: string) => {
    dispatch(removeEnvironmentVariable({ envKey: selectedEnv, variableId }));
  };

  const handleAddVariable = () => {
    const newVarId = generateUniqueID();
    dispatch(setEnvironmentVariable({ envKey: selectedEnv, variableId: newVarId, name: '', value: '' }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider', padding: 1 }}>
        <Tabs
          orientation="horizontal"
          value={selectedEnv}
          onChange={(e, newValue) => setSelectedEnv(newValue)}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {Object.keys(tempVariables??{}).map(envKey => (
            <Tab key={envKey} label={envKey} value={envKey} />
          ))}
        </Tabs>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ marginLeft: 2 }}
        >
          Add Environment
        </Button>
      </Box>

      <Box sx={{ flex: 1, padding: 1, overflow: 'auto' }}>
        {selectedEnv && tempVariables[selectedEnv] && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Variable Name</TableCell>
                <TableCell>Variable Value</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempVariables[selectedEnv].variables?.map(({ id, name, value }) => (
                <TableRow key={id}>
                  <TableCell onChange={(e) => console.log("Changed")}>
                    <TextField
                      value={name}
                      onChange={(e) => handleVariableRename(id, e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={value}
                      onChange={(e) => handleVariableChange(id, e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemoveVariable(id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <IconButton onClick={handleAddVariable} color="primary">
                    <AddIcon />
                  </IconButton>
                </TableCell>
                <TableCell colSpan={2}></TableCell> {/* Adjusting colspan for spacing */}
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-environment-modal-title"
        aria-describedby="add-environment-modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <h2 id="add-environment-modal-title">Add New Environment</h2>
          <TextField
            label="Environment Name"
            value={newEnvName}
            onChange={(e) => setNewEnvName(e.target.value)}
            fullWidth
          />
          <Box sx={{ marginTop: 2 }}>
            <Button onClick={handleAddEnvironment} variant="contained" color="primary">
              Add
            </Button>
            <Button onClick={handleCloseModal} sx={{ marginLeft: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default EnvironmentSettings;