import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Test } from "../api";
import { IListSuggestedVariablesRequest, IStep, VariableSuggestion } from '../test-studio/models';
import { selectTestStepExecutionRecords } from './testsSlice';
import { RootState } from '../app/store';

interface SuggestedVariablesState {
  isOpen: boolean;
  variableSuggestions: VariableSuggestion[];
  selectedVariables: Record<string, boolean>;
  selectedVariableUsages: Record<string, boolean>;
  loading: boolean;
  error: string | null;
}

const initialState: SuggestedVariablesState = {
  isOpen: false,
  variableSuggestions: [],
  selectedVariables: {},
  selectedVariableUsages: {},
  loading: false,
  error: null,
};

export const fetchVariableSuggestions = createAsyncThunk<
VariableSuggestion[],
  void,
  { state: RootState }
>('variableSuggestions/fetchVariableSuggestions', async (_, { getState, dispatch }) => {

  const state = getState();
  const executionRecords = selectTestStepExecutionRecords(state.tests);
  const steps :IStep[]= state.tests.steps;
  const testResponses = state.tests.testResponse;

  let showError = steps.some((step) => {
    const rawResponse = testResponses[step.id];
    return !rawResponse;
  });

  const request: IListSuggestedVariablesRequest = {
    testStepRecords: executionRecords,
  };

  const response = await Test.listSuggestedVariables(request);
  return response.variableSuggestions!;
});

const suggestedVariablesSlice = createSlice({
  name: 'suggestedVariables',
  initialState,
  reducers: {
    openSuggestedVariablesPopup(state) {
      state.isOpen = true;
    },
    closeSuggestedVariablesPopup(state) {
      state.isOpen = false;
      state.selectedVariables = {};
      state.selectedVariableUsages = {};
    },
    toggleVariableSelection(state, action: PayloadAction<string>) {
      const variableId = action.payload;
      state.selectedVariables[variableId] = !state.selectedVariables[variableId];
      if (state.selectedVariables[variableId]) {
        state.variableSuggestions.find(v => v.suggestionId === variableId)?.variableUsageSuggestions?.forEach(usage => {
          state.selectedVariableUsages[usage.usageSuggestionId] = true;
        });
      } else {
        state.variableSuggestions.find(v => v.suggestionId === variableId)?.variableUsageSuggestions?.forEach(usage => {
          state.selectedVariableUsages[usage.usageSuggestionId] = false;
        });
      }
      console.log("toggleVariableSelection:", state.selectedVariables, state.selectedVariableUsages);
    },
    toggleVariableUsageSelection(state, action: PayloadAction<string>) {
      const usageId = action.payload;
      state.selectedVariableUsages[usageId] = !state.selectedVariableUsages[usageId];
      console.log("toggleVariableUsageSelection:", state.selectedVariableUsages);
    },
    clearSelections(state) {
      state.selectedVariables = {};
      state.selectedVariableUsages = {};
    },
    selectAllVariableUsages(state, action: PayloadAction<string>) {
      const variableId = action.payload;
      const variable = state.variableSuggestions.find(v => v.suggestionId === variableId);
      if (variable) {
        variable.variableUsageSuggestions?.forEach(usageConfig => {
          state.selectedVariableUsages[usageConfig.usageSuggestionId] = true;
        });
      }
      console.log("selectAllVariableUsages:", state.selectedVariableUsages);
    },
    deselectAllVariableUsages(state, action: PayloadAction<string>) {
      const variableId = action.payload;
      const variable = state.variableSuggestions.find(v => v.suggestionId === variableId);
      if (variable) {
        variable.variableUsageSuggestions?.forEach(usageConfig => {
          state.selectedVariableUsages[usageConfig.usageSuggestionId] = false;
        });
      }
      console.log("deselectAllVariableUsages:", state.selectedVariableUsages);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVariableSuggestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVariableSuggestions.fulfilled, (state, action) => {
        state.loading = false;
        state.variableSuggestions = action.payload ?? [];
        console.log("fetchSuggestedVariables.fulfilled:", state.variableSuggestions);
      })
      .addCase(fetchVariableSuggestions.rejected, (state, action) => {
        state.loading = false;
        state.error = 'Failed to fetch variable suggestions';
      });
  },
});

export const {
  openSuggestedVariablesPopup,
  closeSuggestedVariablesPopup,
  toggleVariableSelection,
  toggleVariableUsageSelection,
  clearSelections,
  selectAllVariableUsages,
  deselectAllVariableUsages,
} = suggestedVariablesSlice.actions;

export default suggestedVariablesSlice.reducer;
