import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Google } from '@mui/icons-material';
import '../styles/homestyles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../common/theme';
import { storeUserInfoInLocalStorage, createUser } from '../api/services/User';
import FirebaseService from '../auth/Firebase'
import { UserCredential, getAdditionalUserInfo, AdditionalUserInfo, GoogleAuthProvider } from 'firebase/auth';
import { getVersionedUrl } from '../common/Util';
import LocalStorageManager from '../common/LocalStorageManager';
import { Modal } from 'antd';

const DEMO_PROJECT_ID = process.env.REACT_APP_DEMO_PROJECT_ID;
const DEMO_RESOURCE_ID = process.env.REACT_APP_DEMO_RESOURCE_ID;
const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [user, setUser] = useState<any | null>();
    const [validatingUser, setValidatingUser] = useState<Boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string>("/sessions");

    useEffect(() => {
        async function isLoggedIn() {
            if (FirebaseService.isAuthenticated() === true) {
                const user = FirebaseService.getCurrentUser();
                console.log("Authenticated User", user)
                const userInfo = await storeUserInfoInLocalStorage();
                window.location.href = BASE_APP_URL + "/sessions";
            }
        }
        isLoggedIn();
        const urlParams = new URLSearchParams(window.location.search);
        const flow = urlParams.get('flow');
        if (flow == "replay") {
            const sessionId = urlParams.get('session_id');
            const projectId = urlParams.get('project_id');
            let redirectUrl = '/sessions';
            if (sessionId) {
                redirectUrl = `/replay?session_id=${sessionId}`;
            }
            if (projectId) {
                redirectUrl += `&project_id=${projectId}`;
            }
            setRedirectUrl(redirectUrl);
        }
    }, [])

    const GoogleSignInButton = styled(Button)({
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            background: '#f1f1f1',
        },
    });
    const GoogleSignInIcon = styled(Google)({
        marginRight: '8px',
        color: 'none'
    });

    const storeUserInfoAndRedirect = async () => {
        await storeUserInfoInLocalStorage();
        let organizationId: string = LocalStorageManager.getOrganizationId();
        let orgStatus: string = LocalStorageManager.getOrganizationStatus();
        let emailVerificationStatus: string = LocalStorageManager.getEmailVerificationStatus();
        if (emailVerificationStatus !== "EMAIL_VERIFIED") {
            setIsErrorMessage(true);
            setMessage("Your email address is not yet verified. Please check your inbox for mail with verification link");
            return;
        }

        // If no org, then redirect to create org
        if (!organizationId) {
            window.location.href = BASE_APP_URL + "/createOrg";
        } else {
            if (orgStatus != "ACTIVE_ORG") {
                Modal.info({
                    title: 'Action Required',
                    content: 'Your free trial has ended. Please upgrade to premium to continue using TestChimp',
                    onOk() { window.location.href = BASE_APP_URL + "/admin-settings"; },
                });
            } else {
                window.location.href = BASE_APP_URL + redirectUrl;
            }
        }
    };

    const handleSignin = async () => {
        try {

            const user: UserCredential = await FirebaseService.signInWithEmailAndPassword(email, password);
            console.log('Sign-in successful');
            await storeUserInfoAndRedirect();
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Error signing in. Please check your credentials.")
        }
    };

    const handleGoogleSignin = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithGoogle();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result)
            if (additionalUserInfo && additionalUserInfo.isNewUser) {
                const currentUser = await FirebaseService.getCurrentUser();
                if (currentUser && 'email' in currentUser && 'displayName' in currentUser) {
                    const currentUserEmail: string = typeof currentUser.email === 'string' ? currentUser.email : '';
                    const currentUserDispalyName: string = typeof currentUser.displayName === 'string' ? currentUser.displayName : '';
                    saveGoogleSignedUpUser(currentUserEmail, currentUserDispalyName, currentUser.emailVerified, currentUser.uid)
                }
            }
            await storeUserInfoAndRedirect();
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to Signup User with Google");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDispalyName: string, emailVerified: boolean, uid: string) => {
        const userResponse = await createUser.signupUser(currentUserEmail, currentUserDispalyName, password, String(emailVerified), "GOOGLE", uid, "", "");
        if (userResponse.data.responseHeader?.status != "SUCCESS") {
            if (userResponse.data.responseHeader?.failureReason == "USER_FOR_EMAIL_ALREADY_EXISTS") {
                await storeUserInfoAndRedirect();
            }
        }
    }


    const handleResetPassword = async () => {
        try {
            await FirebaseService.sendPasswordResetEmail(email);
            setIsErrorMessage(false);
            setMessage("Please check your email for instructions to reset password.");
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("An error occured while sending the password reset email");
        }
    };
    let messageColor = "green";
    if (isErrorMessage) {
        messageColor = "red";
    }
    return (
        <ThemeProvider theme={theme}>
            <div className={'center_ten_padding_top'}>
                <Box
                    component="form"
                    borderRadius={5}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        p: 5
                    }}
                    border={'2px outset'}
                    boxShadow={'0 0 3px primary, 0 0 5px'}
                    style={{ backgroundColor: "white" }}
                >
                    <Typography variant='h4'>Sign in</Typography>
                    <div>
                        <TextField id="user-email"
                            label="Email"
                            size="small"
                            variant="standard"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <TextField id="user-password"
                            label="Password"
                            type="password"
                            size="small"
                            variant="standard"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button id="login-button" sx={{ mt: 3 }} variant="contained" color='primary' onClick={handleSignin}>
                            Sign in
                        </Button>
                    </div>
                    <div style={{ marginTop: '20px', marginBottom: '10px', color: 'rgba(0, 0, 0, 0.54)' }}>
                        <hr style={{ display: 'inline-block', verticalAlign: 'middle', width: '30%', height: '1px', background: 'grey' }} />
                        <Typography style={{ display: 'inline', fontWeight: 'bold' }}> Or </Typography>
                        <hr style={{ display: 'inline-block', verticalAlign: 'middle', width: '30%', height: '1px', background: 'grey' }} />
                        <GoogleSignInButton onClick={handleGoogleSignin}>
                            <GoogleSignInIcon />
                            Continue with Google
                        </GoogleSignInButton>
                    </div>
                    <div>
                        <Button variant="text" sx={{ mt: 1, textTransform: 'none' }} onClick={handleResetPassword}>Reset Password</Button>
                        <Button variant="text" sx={{ mt: 1, textTransform: 'none' }} component={Link} to={getVersionedUrl("/signup")}>Create an account</Button>
                    </div>
                </Box>
                <div><h3 id="status-message" style={{ color: messageColor, justifyContent: "center", alignItems: "center" }}>{message}</h3></div>
            </div>
        </ThemeProvider>
    );
}

export default SignIn;